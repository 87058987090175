import { Component, EventEmitter, Input, OnDestroy, OnInit, Optional, Output } from '@angular/core';
import { CommonService } from '@src/app/services/common.service';
import { formatNumberToKMB } from '@src/app/shared/utils/number.util';
import { environment } from '@src/environments/environment';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { finalize } from 'rxjs/operators';
import { MemeDashboardService } from '../../meme-dashboard.service';
import { MemeAgentUserSettings } from '../../meme-dashboard.type';

type OptionType = 'quickSelect' | 'custom';
type FieldType = 'marketCap' | 'txVolume24h' | 'liquidity' | 'holders' | 'top10Holders';

@Component({
    selector: 'app-meme-star-settings',
    templateUrl: './meme-star-settings.component.html',
    styleUrls: ['./meme-star-settings.component.less'],
})
export class MemeStarSettingsComponent implements OnInit, OnDestroy {
    @Input() isEmbedded = false; // 新增：标识组件是否被嵌入页面而非drawer
    @Output() saved = new EventEmitter<void>();
    @Output() laterClicked = new EventEmitter<void>();

    marketCap: [number, number];
    marketCapType: OptionType = 'quickSelect';
    txVolume24h: [number, number];
    txVolume24hType: OptionType = 'quickSelect';
    liquidity: [number, number];
    liquidityType: OptionType = 'quickSelect';
    holders: [number, number];
    holdersType: OptionType = 'quickSelect';
    top10Holders: number = null;

    formatter = (value: number): string => {
        return value ? formatNumberToKMB(value) : '';
    };

    parser = (value: string): string => {
        return value.replace(/[^\d.]/g, '');
    };

    disableReminder = false;

    marketCapOptions: { label: string; value: [number, number] }[] = [
        { label: '<100K', value: [null, 100000] },
        { label: '100K-50M', value: [100000, 50000000] },
        { label: '>50M', value: [50000000, null] },
    ];

    txVolume24hOptions: { label: string; value: [number, number] }[] = [
        { label: '>50K', value: [50000, null] },
        { label: '>100K', value: [100000, null] },
        { label: '>500K', value: [500000, null] },
    ];

    liquidityOptions: { label: string; value: [number, number] }[] = [
        { label: '<10K', value: [null, 10000] },
        { label: '10K-5M', value: [10000, 5000000] },
        { label: '>5M', value: [5000000, null] },
    ];

    holdersOptions: { label: string; value: [number, number] }[] = [
        { label: '>50', value: [50, null] },
        { label: '>100', value: [100, null] },
        { label: '>500', value: [500, null] },
    ];

    submitting = false;

    loading = false;

    errorMsg: string;

    private checkInitTimer: any;

    get starLevel() {
        let level = 0;
        !this.isEmptyFiled(this.txVolume24h) && level++;
        !this.isEmptyFiled(this.liquidity) && level++;
        !this.isEmptyFiled(this.holders) && level++;
        !this.isEmptyFiled(this.marketCap) && level++;
        this.top10Holders && level++;
        return level;
    }

    constructor(private memeDashboardService: MemeDashboardService, public commonService: CommonService, @Optional() private drawerRef: NzDrawerRef) {}

    ngOnInit() {
        this.loading = true;
        this.memeDashboardService.getMemeAgentUserSettings().subscribe({
            next: res => this.setupInitData(res),
            error: err => this.commonService.errorMessageByResponse(err),
            complete: () => (this.loading = false),
        });
    }

    ngOnDestroy() {
        if (this.checkInitTimer) {
            clearInterval(this.checkInitTimer);
        }
    }

    goToTgAgent() {
        window.open(`${environment.telegram.botLink}`, '_blank');
    }

    toggleInputType(type: FieldType) {
        if (type === 'marketCap') {
            this.marketCapType = this.marketCapType === 'quickSelect' ? 'custom' : 'quickSelect';
            this.marketCap = [null, null];
        }

        if (type === 'txVolume24h') {
            this.txVolume24hType = this.txVolume24hType === 'quickSelect' ? 'custom' : 'quickSelect';
            this.txVolume24h = [null, null];
        }

        if (type === 'liquidity') {
            this.liquidityType = this.liquidityType === 'quickSelect' ? 'custom' : 'quickSelect';
            this.liquidity = [null, null];
        }

        if (type === 'holders') {
            this.holdersType = this.holdersType === 'quickSelect' ? 'custom' : 'quickSelect';
            this.holders = [null, null];
        }
    }

    isEmptyFiled(data: [number, number]) {
        return data[0] === null && data[1] === null;
    }

    revertFieldData(type: FieldType) {
        this.errorMsg = null;
        switch (type) {
            case 'marketCap':
                this.marketCap = [null, null];
                break;
            case 'txVolume24h':
                this.txVolume24h = [null, null];
                break;
            case 'liquidity':
                this.liquidity = [null, null];
                break;
            case 'holders':
                this.holders = [null, null];
                break;
            case 'top10Holders':
                this.top10Holders = null;
                break;
        }
    }

    reset() {
        this.errorMsg = null;
        ['marketCap', 'txVolume24h', 'liquidity', 'holders', 'top10Holders'].forEach(type => {
            this.revertFieldData(type as FieldType);

            switch (type) {
                case 'marketCap':
                    this.marketCapType = 'quickSelect';
                    break;
                case 'txVolume24h':
                    this.txVolume24hType = 'quickSelect';
                    break;
                case 'liquidity':
                    this.liquidityType = 'quickSelect';
                    break;
                case 'holders':
                    this.holdersType = 'quickSelect';
                    break;
            }
        });
    }

    saveSettings() {
        if (this.starLevel > 0 && this.starLevel < 3) {
            this.errorMsg = 'Please set at least 3 stars';
            return;
        }

        this.submitting = true;

        this.memeDashboardService
            .setMemeAgentUserSettings({
                my_watchlist_settings: {
                    market_cap: this.marketCap,
                    tx_volume_24h: this.txVolume24h,
                    liquidity: this.liquidity,
                    holders: this.holders,
                    top_10_holders_percentage: this.top10Holders,
                },
                agent_reminder: !this.disableReminder,
            })
            .pipe(finalize(() => (this.submitting = false)))
            .subscribe({
                next: () => {
                    this.commonService.success('Settings saved successfully');

                    if (this.isEmbedded) {
                        this.saved.emit();
                    } else {
                        this.drawerRef?.close();
                    }
                },
                error: err => this.commonService.errorMessageByResponse(err),
            });
    }

    private setupInitData(data: MemeAgentUserSettings) {
        // 处理 marketCap
        const marketCapFromOptions = this.marketCapOptions.find(
            item => item.value[0] === data.my_watchlist_settings?.market_cap?.[0] && item.value[1] === data.my_watchlist_settings?.market_cap?.[1]
        )?.value;

        if (marketCapFromOptions) {
            this.marketCap = marketCapFromOptions;
            this.marketCapType = 'quickSelect';
        } else if (data.my_watchlist_settings?.market_cap && !this.isEmptyFiled(data.my_watchlist_settings.market_cap as [number, number])) {
            this.marketCap = data.my_watchlist_settings.market_cap as [number, number];
            this.marketCapType = 'custom';
        } else {
            this.marketCap = [null, null];
            this.marketCapType = 'quickSelect';
        }

        // 处理 txVolume24h
        const txVolumeFromOptions = this.txVolume24hOptions.find(
            item => item.value[0] === data.my_watchlist_settings?.tx_volume_24h?.[0] && item.value[1] === data.my_watchlist_settings?.tx_volume_24h?.[1]
        )?.value;

        if (txVolumeFromOptions) {
            this.txVolume24h = txVolumeFromOptions;
            this.txVolume24hType = 'quickSelect';
        } else if (data.my_watchlist_settings?.tx_volume_24h && !this.isEmptyFiled(data.my_watchlist_settings.tx_volume_24h as [number, number])) {
            this.txVolume24h = data.my_watchlist_settings.tx_volume_24h as [number, number];
            this.txVolume24hType = 'custom';
        } else {
            this.txVolume24h = [null, null];
            this.txVolume24hType = 'quickSelect';
        }

        // 处理 liquidity
        const liquidityFromOptions = this.liquidityOptions.find(
            item => item.value[0] === data.my_watchlist_settings?.liquidity?.[0] && item.value[1] === data.my_watchlist_settings?.liquidity?.[1]
        )?.value;

        if (liquidityFromOptions) {
            this.liquidity = liquidityFromOptions;
            this.liquidityType = 'quickSelect';
        } else if (data.my_watchlist_settings?.liquidity && !this.isEmptyFiled(data.my_watchlist_settings.liquidity as [number, number])) {
            this.liquidity = data.my_watchlist_settings.liquidity as [number, number];
            this.liquidityType = 'custom';
        } else {
            this.liquidity = [null, null];
            this.liquidityType = 'quickSelect';
        }

        // 处理 holders
        const holdersFromOptions = this.holdersOptions.find(
            item => item.value[0] === data.my_watchlist_settings?.holders?.[0] && item.value[1] === data.my_watchlist_settings?.holders?.[1]
        )?.value;

        if (holdersFromOptions) {
            this.holders = holdersFromOptions;
            this.holdersType = 'quickSelect';
        } else if (data.my_watchlist_settings?.holders && !this.isEmptyFiled(data.my_watchlist_settings.holders as [number, number])) {
            this.holders = data.my_watchlist_settings.holders as [number, number];
            this.holdersType = 'custom';
        } else {
            this.holders = [null, null];
            this.holdersType = 'quickSelect';
        }

        // 处理 top10Holders
        this.top10Holders = data.my_watchlist_settings?.top_10_holders_percentage || null;

        // 设置提醒状态
        this.disableReminder = data.agent_reminder === false;
    }
}
