// Telegram WebApp 自动嵌入的用户信息
export interface TgUserData {
    id: number;
    first_name: string;
    last_name: string;
    username: string;
    language_code: string;
    allows_write_to_pm: boolean;
    photo_url?: string;
}

// 也是 Telegram WebApp 自动嵌入的用户信息，但是可以用于 Auth
export interface TGUnSafeUserData {
    query_id: string;
    user: TgUserData;
    auth_date: string;
    hash: string;
}

export enum AccountType {
    ONLY_TG = 'tg',
    ONLY_WALLET = 'wallet',
    TG_WALLET = 'tg_wallet',
    NOT_CONNECTED = 'not_connected',
}
