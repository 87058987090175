import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MemeDashboardService } from '@src/app/modules/meme-dashboard/meme-dashboard.service';
import { SearchedToken } from '@src/app/modules/meme-dashboard/meme-dashboard.type';
import { getTokenMarketCapType } from '@src/app/modules/meme-dashboard/meme-dashboard.util';
import { SharedModule } from '@src/app/shared/shared.module';
import { getChainImg } from '@src/app/shared/types/wallet-chain.type';
import { getAge } from '@src/app/shared/utils/common.util';
import { formatNumberWithPrefix } from '@src/app/shared/utils/number.util';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { debounceTime, distinctUntilChanged, filter, Subject, switchMap, tap } from 'rxjs';

@Component({
    standalone: true,
    selector: 'app-meme-search',
    templateUrl: './meme-search.component.html',
    styleUrls: ['./meme-search.component.less'],
    imports: [CommonModule, SharedModule, NzDropDownModule],
    providers: [MemeDashboardService],
})
export class MemeSearchComponent {
    getAge = getAge;
    getTokenMarketCapType = getTokenMarketCapType;
    getChainImg = getChainImg;
    formatNumberWithPrefix = formatNumberWithPrefix;
    isNaN = isNaN;

    // 搜索相关
    searchChange$ = new Subject<string>();
    searchedTokens: SearchedToken[] = [];
    searchMenuVisible = false;
    searchingToken = false;
    searchedAddress: string;
    visitedTokens: SearchedToken[] = [];
    searchedTokenMarketCapChangeMap: Record<string, number> = {};

    constructor(private memeDashboardService: MemeDashboardService) {}

    ngOnInit() {
        this.setupSearchChange();
    }

    // 搜索相关方法
    private setupSearchChange() {
        this.searchChange$
            .pipe(
                debounceTime(500),
                distinctUntilChanged(),
                filter(value => value?.length > 2),
                tap(() => (this.searchingToken = true)),
                switchMap(value => this.memeDashboardService.searchToken(value)),
                tap({ error: () => (this.searchingToken = false) })
            )
            .subscribe({
                next: res => {
                    this.searchedTokens = res.data.slice(0, 30);
                    this.searchMenuVisible = true;
                    this.searchingToken = false;
                    this.searchedTokens.forEach(token => {
                        const lastTokenMC = token.price_history[token.price_history.length - 1]?.market_cap;
                        const firstTokenMC = token.price_history[0]?.market_cap;
                        this.searchedTokenMarketCapChangeMap[token.token_address] = (lastTokenMC - firstTokenMC) / firstTokenMC;
                    });
                },
                error: () => {
                    this.searchingToken = false;
                    this.searchedTokens = [];
                },
            });
    }

    // UI 事件处理方法
    onSearch(value: string) {
        this.searchChange$.next(value);
    }

    searchFocused() {
        if (this.searchedAddress || this.visitedTokens?.length) {
            this.searchMenuVisible = true;
        } else {
            this.searchMenuVisible = false;
        }
    }

    setVisitedTokens(token: SearchedToken) {
        const existingIndex = this.visitedTokens.findIndex(item => item.token_address === token.token_address);

        if (existingIndex !== -1) {
            this.visitedTokens.splice(existingIndex, 1);
        }

        this.visitedTokens.push(token);

        if (this.visitedTokens.length > 4) {
            this.visitedTokens = this.visitedTokens.slice(-4);
        }

        localStorage.setItem('ss_meme_visitedTokens', JSON.stringify(this.visitedTokens));
    }

    clearVisitedTokens() {
        this.visitedTokens = [];
        localStorage.removeItem('ss_meme_visitedTokens');
    }
}
