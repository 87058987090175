<div class="step-box" *ngIf="moreThanOneSteps">
    <div class="progress-line" [style.width]="getProgressLineWidth() + '%'"></div>

    <div class="step-item" *ngIf="steps.step1Enabled">
        <div class="step-num" [class.completed]="isStepCompleted(1)" [class.current]="isStepCurrent(1)">
            <ng-container *ngIf="isStepCompleted(1); else numberTemplate"></ng-container>
            <img *ngIf="isStepCompleted(1)" src="/assets/imgs/icon/check.png" class="w-6 h-6" alt="" />
            <ng-template #numberTemplate>{{calculateRealStepNum(1)}}</ng-template>
        </div>
        <div class="step-item-title">
            <span>Add your Watchlist</span>
        </div>
    </div>

    <div class="step-item" *ngIf="steps.step2Enabled">
        <div class="step-num" [class.completed]="isStepCompleted(2)" [class.current]="isStepCurrent(2)">
            <ng-container *ngIf="isStepCompleted(2); else number2Template"></ng-container>
            <img *ngIf="isStepCompleted(2)" src="/assets/imgs/icon/check.png" class="w-6 h-6" alt="" />
            <ng-template #number2Template>{{calculateRealStepNum(2)}}</ng-template>
        </div>
        <div class="step-item-title">
            <span>Watchlist Settings</span>
        </div>
    </div>

    <div class="step-item" *ngIf="steps.step3Enabled">
        <div class="step-num" [class.completed]="isStepCompleted(3)" [class.current]="isStepCurrent(3)">
            <ng-container *ngIf="isStepCompleted(3); else number3Template"></ng-container>
            <img *ngIf="isStepCompleted(3)" src="/assets/imgs/icon/check.png" class="w-6 h-6" alt="" />
            <ng-template #number3Template>{{calculateRealStepNum(3)}}</ng-template>
        </div>
        <div class="step-item-title">
            <span>Manage Stars 🌟 for Watchlist Alert</span>
        </div>
    </div>

    <div class="step-item" *ngIf="steps.step4Enabled">
        <div class="step-num" [class.completed]="isStepCompleted(4)" [class.current]="isStepCurrent(4)">
            <ng-container *ngIf="isStepCompleted(4); else number4Template"></ng-container>
            <img *ngIf="isStepCompleted(4)" src="/assets/imgs/icon/check.png" class="w-6 h-6" alt="" />
            <ng-template #number4Template>{{calculateRealStepNum(4)}}</ng-template>
        </div>
        <div class="step-item-title">
            <span>Set up SocialScan Hunter Agent to receive alerts</span>
        </div>
    </div>
</div>

<!-- Step 1: Add your Watchlist -->
<section *ngIf="steps.current === 1">
    <h1 class="mb-3 flex-start-center gap-x-1.5">
        <img src="/assets/imgs/chains/base.png" class="w-5 h-5" alt="" />
        <span>Track wallets of your choice</span>
    </h1>

    <div class="my-8 flex-1">
        <textarea
            nz-input
            [nzAutosize]="{ minRows: 5 }"
            class="h-full"
            style="resize: none; height: 390px"
            [(ngModel)]="watchListText"
            (ngModelChange)="invalidMessage = ''"
        ></textarea>
    </div>

    <div class="text-error mb-4" *ngIf="showError">
        <div *ngIf="invalidMessage">{{invalidMessage}}</div>
        <div *ngIf="addedInfo.duplicatedCount > 0" class="flex-start-center flex-wrap gap-1">
            <div>
                {{addedInfo.duplicatedCount}} duplicated address{{addedInfo.duplicatedCount > 1 ? 'es' : ''}}, line number:
                <ng-container *ngFor="let index of addedInfo.duplicatedAddressLineNumbers; let last = last">{{index}}{{!last ? ',' : ''}}</ng-container>
            </div>
        </div>
        <div *ngIf="addedInfo.notSupportedCount > 0" class="flex-start-center flex-wrap gap-1">
            <div>
                {{addedInfo.notSupportedCount}} address{{addedInfo.notSupportedCount > 1 ? 'es' : ''}} not supported, line number:
                <ng-container *ngFor="let index of addedInfo.notSupportedAddressLineNumbers; let last = last">{{index}}{{!last ? ',' : ''}}</ng-container>
            </div>
        </div>
        <div *ngIf="addedInfo.solanaCount > 0" class="flex-start-center flex-wrap gap-1">
            <div>
                {{addedInfo.solanaCount}} Solana address{{addedInfo.solanaCount > 1 ? 'es' : ''}} not supported (coming soon), line number:
                <ng-container *ngFor="let index of addedInfo.solanaAddressLineNumbers; let last = last">{{index}}{{!last ? ',' : ''}}</ng-container>
            </div>
        </div>
    </div>

    <div class="flex-between-center gap-x-4 sticky bottom-0 bg-white pt-2">
        <button nz-button class="flex-1" (click)="drawerRef.close()">Later</button>
        <button nz-button nzType="primary" class="flex-1" (click)="confirmAdd()" [disabled]="!watchListText" [nzLoading]="submitting">Add</button>
    </div>
</section>

<!-- Step 2: Add your Watchlist -->
<section *ngIf="steps.current === 2" class="space-y-6 md:space-y-8">
    <div class="text-sm font-medium">Give your watchlist a name, define its profile, and craft an introduction—so everyone knows exactly what it does.</div>

    <div>
        <div class="text-sm mb-2 font-semibold">Watchlist Name <span class="text-error">*</span></div>
        <input nz-input type="text" class="w-full" [(ngModel)]="agentInfo.name" />
    </div>

    <div>
        <div class="text-sm mb-2 font-semibold">Watchlist Profile <span class="text-error">*</span></div>
        <nz-upload [nzShowUploadList]="false" [(nzFileList)]="fileList" [nzBeforeUpload]="beforeUpload" nzAccept="image/*">
            <div class="flex-center-center w-[72px] h-[72px] rounded-full bg-secondaryCard relative" appLoading [loading]="uploading">
                <div class="absolute w-[28px] h-[28px] rounded-full bg-black flex-center-center bottom-0 -right-2">
                    <img src="/assets/imgs/icon/v2-edit.png" class="w-4" alt="" />
                </div>
                <img *ngIf="agentInfo.logo" [src]="agentInfo.logo" class="w-full h-full rounded-full" alt="" />
            </div>
        </nz-upload>
    </div>

    <div>
        <div class="text-sm mb-2 font-semibold">Watchlist Introduction</div>

        <textarea nz-input style="resize: none; height: 120px" [(ngModel)]="agentInfo.introduction"></textarea>
    </div>

    <div>
        <div class="mb-2">
            <span nz-icon nzType="antd:x" class="!text-xl"></span>
        </div>
        <input
            *ngIf="walletStatusService.userProfile?.twitter_username"
            type="text"
            nz-input
            placeholder="X Name"
            disabled
            class="w-full h-14"
            [value]="walletStatusService.userProfile?.twitter_username"
        />
        <button *ngIf="!walletStatusService.userProfile?.twitter_username" nz-button (click)="connectTwitter()" class="w-full connect-btn" nzSize="large">
            Connect X
        </button>
    </div>

    <div class="sticky bottom-0 bg-white space-y-6 pt-2">
        <div class="flex-between-center gap-x-4">
            <button nz-button class="flex-1" (click)="drawerRef.close()">Later</button>
            <button
                nz-button
                nzType="primary"
                class="flex-1"
                (click)="confirmEditAgentInfo()"
                [disabled]="agentInfoNextBtnDisabled"
                [nzLoading]="savingAgentInfo"
            >
                {{onlyUpdateAgentInfo ? 'Save' : 'Next'}}
            </button>
        </div>

        <div *ngIf="hasWatchlistWallets" class="flex-center-center text-sub-text gap-x-1">
            <nz-switch nzSize="small" [(ngModel)]="agentInfo.allowSubscription"></nz-switch>
            <div class="text-xs">People can subscribe your Watchlist</div>
            <span
                nz-icon
                nzType="info-circle"
                class="!text-lg"
                nz-tooltip="When set to 'can subscribe', your Watchlist becomes public to others.  You will be rewarded 80 points for each subscriber."
            ></span>
        </div>
    </div>
</section>

<!-- Step 3: Setup MEME Star Settings -->
<div *ngIf="steps.current === 3" class="space-y-6 md:space-y-8">
    <app-meme-star-settings [isEmbedded]="true" (saved)="starSettingsSaved()" (laterClicked)="drawerRef.close()"></app-meme-star-settings>
</div>

<!-- Step 4: Setup MEME TG Agent -->
<div *ngIf="steps.current === 4" class="space-y-6 md:space-y-8">
    <div class="text-sm font-medium">Set up SocialScan Hunter Agent now to receive alerts from your Watchlist.</div>

    <div class="flex-between-center gap-x-4 mt-8">
        <button nz-button class="flex-1" (click)="drawerRef.close()">Later</button>
        <button nz-button nzType="primary" class="flex-1" (click)="goToTgAgent()">
            <span nz-icon nzType="antd:colorful-telegram" class="!text-xl mr-1"></span>
            <div class="colorful-text text-sm font-medium">TG Hunter Agent</div>
        </button>
    </div>
</div>

<ng-template #successAlert>
    <div class="text-base text-left">
        <div class="font-bold">Success!</div>
        <div *ngIf="addedInfo.addedCount > 0" class="text">{{addedInfo.addedCount}} address{{addedInfo.addedCount > 1 ? 'es' : ''}} added</div>
        <div *ngIf="addedInfo.duplicatedCount > 0" class="text">
            {{addedInfo.duplicatedCount}} address{{addedInfo.duplicatedCount > 1 ? 'es' : ''}} duplicated
        </div>
        <div *ngIf="addedInfo.notSupportedCount > 0" class="text">
            {{addedInfo.notSupportedCount}} address{{addedInfo.notSupportedCount > 1 ? 'es' : ''}} not supported
        </div>
    </div>
</ng-template>
