import { Injectable } from '@angular/core';
import { RequestService } from './request.service';

/**
 * 处理所有与 Telegram 相关的 API 请求
 * 该服务负责封装所有与后端 Telegram 相关的接口调用
 * 该服务只会被 Inject 到其他服务中，自身不会注入其他服务
 */
@Injectable({
    providedIn: 'root',
})
export class TelegramApiService {
    constructor(private requestService: RequestService) {}

    /**
     * 将用户的钱包地址与当前登录的 Telegram 账号关联
     * @param data.wallet_address - 用户的钱包地址
     * @param data.message - 待签名的消息
     * @param data.signature - 钱包签名后的消息
     * @returns Promise 包含关联结果
     */
    connectWalletWithTG(data: { wallet_address: string; message: string; signature: string }) {
        return this.requestService.sendRequest({
            method: 'POST',
            url: '/v1/socialscan/user/connect_wallet',
            data,
        });
    }

    /**
     * 使用 Telegram WebApp 进行登录
     * @param initData - Telegram WebApp 初始化数据
     * @param code - 可选的授权码
     * @returns Promise 包含登录结果，包括认证token和用户信息
     */
    loginWithTG(initData: any, code?: string) {
        return this.requestService.sendRequest<{
            auth: string;
            registration_complete: true;
            telegram_id: string;
            user_id: string;
            wallet_address?: string;
        }>({
            method: 'POST',
            url: '/v1/socialscan/user/login_with_tg',
            data: {
                init_data: initData,
                ...(code && { code }),
            },
        });
    }

    /**
     * 将 Telegram 账号绑定到已有的钱包账号
     * @param data - Telegram 用户数据
     * @param data.id - Telegram 用户ID
     * @param data.first_name - 用户名
     * @param data.username - Telegram 用户名
     * @param data.auth_date - 认证时间戳
     * @param data.hash - 认证哈希
     * @returns Promise 包含绑定结果
     */
    bindTelegramToWallet(data: { id: number; first_name: string; username: string; auth_date: number; hash: string }) {
        return this.requestService.sendRequest({
            method: 'POST',
            url: '/v1/socialscan/user/connect_telegram',
            data,
        });
    }
}
