import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { environment } from '@src/environments/environment';
import { BehaviorSubject, filter, fromEvent, take } from 'rxjs';
import { MyProfile } from '../shared/types/address.type';
import { popupWindow } from '../shared/utils/common.util';
import { CommonService } from './common.service';
import { IResponseError, RequestService } from './request.service';
import { UserService } from './user.service';
import { WalletStatusService } from './wallet-status.service';

@Injectable({
    providedIn: 'root',
})
export class SocialmediaAuthService {
    socialMediaWindow$: any;

    connectedTwitterInfo$ = new BehaviorSubject<MyProfile>(null);
    connectedDiscordInfo$ = new BehaviorSubject<MyProfile>(null);
    connectedTelegramInfo$ = new BehaviorSubject<any>(null);

    constructor(
        private requestService: RequestService,
        private walletStatusService: WalletStatusService,
        private commonService: CommonService,
        private userService: UserService
    ) {}

    getRedirectUrl(type: 'discord' | 'twitter') {
        return `${location.protocol}//${location.host}/public/completed-window/${type}`;
    }

    async connectTwitter() {
        const authUrl = `${environment.socialscanApiUrl}/v1/socialscan/user/connect_twitter?redirect_url=${this.getRedirectUrl(
            'twitter'
        )}&user_id=${this.userService.getUserID()}`;
        window.localStorage.setItem('socialscan_back_page', window.location.href);
        window.localStorage.setItem('socialscan_social_media_type', 'twitter');

        popupWindow(authUrl);

        this.socialMediaWindow$?.unsubscribe();
        this.socialMediaWindow$ = fromEvent<StorageEvent>(window, 'storage')
            .pipe(
                filter(e => e.key === 'socialscan_twitter_auth' && !!e.newValue),
                take(1)
            )
            .subscribe(e => {
                const data: { queryParams: Params } = JSON.parse(e.newValue);
                this.getUserInfoByTwitterParams(data.queryParams['code'])
                    .then(data => {
                        this.walletStatusService.userProfile$.next(data);
                        this.connectedTwitterInfo$.next(data);
                    })
                    .catch((err: IResponseError) => {
                        if (err.status === 401) {
                            this.commonService.error('Token expired. Please re-connect your wallet');
                            setTimeout(() => {
                                this.userService.logout();
                            }, 1000);
                        } else {
                            this.commonService.errorMessageByResponse(err);
                        }
                    });
                window.localStorage.removeItem('socialscan_twitter_auth');
                window.localStorage.removeItem('socialscan_social_media_type');
            });
    }

    async connectDiscord() {
        const authUrl = `${environment.socialscanApiUrl}/v1/socialscan/user/connect_discord?redirect_url=${this.getRedirectUrl(
            'discord'
        )}&user_id=${this.userService.getUserID()}`;
        localStorage.setItem('socialscan_back_page', window.location.href);
        localStorage.setItem('socialscan_social_media_type', 'discord');

        popupWindow(authUrl);

        this.socialMediaWindow$?.unsubscribe();
        this.socialMediaWindow$ = fromEvent<StorageEvent>(window, 'storage')
            .pipe(
                filter(e => e.key === 'socialscan_discord_auth' && !!e.newValue),
                take(1)
            )
            .subscribe(e => {
                const data: { queryParams: Params } = JSON.parse(e.newValue);
                this.getUserInfoByDiscordParams(data.queryParams['code'])
                    .then(data => {
                        this.walletStatusService.userProfile$.next(data);
                        this.connectedDiscordInfo$.next(data);
                    })
                    .catch((err: IResponseError) => {
                        if (err.status === 401) {
                            this.commonService.error('Token expired. Please re-connect your wallet');
                            setTimeout(() => {
                                this.userService.logout();
                            }, 1000);
                        } else {
                            this.commonService.errorMessageByResponse(err);
                        }
                    });
                window.localStorage.removeItem('socialscan_discord_auth');
                window.localStorage.removeItem('socialscan_social_media_type');
            });
    }

    async connectTelegram() {
        if (this.commonService.isMobile) {
            return this.commonService.error('Telegram authentication is not supported on mobile devices. Please use a desktop browser.');
        }
        (window as any).Telegram.Login.auth({ bot_id: 7953792687, request_access: 'write' }, (data: any) => {
            if (!data) {
                // authorization failed
                return;
            }

            // Here you would want to validate data like described there
            // https://core.telegram.org/widgets/login#checking-authorization
            if (!data.username) {
                data.username = `${data.first_name} ${data.last_name}`;
            }

            this.connectedTelegramInfo$.next(data);
        });
    }

    getUserInfoByTwitterParams(code: string) {
        return this.requestService
            .sendRequest<MyProfile>({
                url: '/v1/socialscan/user/connect_twitter',
                method: 'POST',
                data: { code, redirect_url: this.getRedirectUrl('twitter') },
            })
            .then(data => {
                if (data.earned_badges?.length) {
                    this.commonService.showGotNewBadgesModal(data.earned_badges, 0, data.earned_invitation_codes);
                }

                return data;
            });
    }

    getUserInfoByDiscordParams(code: string) {
        return this.requestService
            .sendRequest<MyProfile>({
                url: '/v1/socialscan/user/connect_discord',
                method: 'POST',
                data: { code, redirect_url: this.getRedirectUrl('discord') },
            })
            .then(data => {
                if (data.earned_badges?.length) {
                    this.commonService.showGotNewBadgesModal(data.earned_badges);
                }

                return data;
            });
    }
}
