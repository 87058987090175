<div *ngIf="!loading" class="space-y-8 pr-1 h-full overflow-y-auto relative">
    <div *ngIf="!isEmbedded" class="text-sm font-medium">Manage Stars 🌟 for My Watchlist Alert</div>

    <div class="text-sm text-[#2990FFE5] bg-[#4378FF1A] rounded-md p-3">
        You currently have <b>{{starLevel}} stars</b> set for your watchlist alert, meaning the highest possible rating for your alert is
        <b>{{starLevel}} stars</b>. You can set a maximum of <b>5 stars</b> (🌟🌟🌟🌟🌟).
    </div>

    <div class="space-y-4">
        <div class="w-full flex-between-center gap-x-2">
            <div class="text-sm font-medium">Star assigned to each filter</div>

            <div class="text-sub-text text-xs">
                <ng-container *ngFor="let item of [1,2,3,4,5]">
                    <span *ngIf="item <= starLevel">🌟 </span>
                </ng-container>
                <span>
                    <span class="font-semibold text-black">{{starLevel || 0}} </span>
                    <span> star</span>
                    <span *ngIf="starLevel > 1">s</span>
                </span>
            </div>
        </div>

        <div class="p-4 rounded-lg border border-line flex flex-col gap-y-4 w-full justify-start items-stretch">
            <div class="filter-box-item">
                <div class="font-medium text-black">1. 🌟 24h Volume($)</div>
                <div class="flex-start-center gap-x-3 mt-3">
                    <ng-container *ngIf="txVolume24hType === 'quickSelect'">
                        <button
                            *ngFor="let item of txVolume24hOptions"
                            nz-button
                            nzSize="small"
                            (click)="txVolume24h = item.value"
                            class="option-item"
                            [ngClass]="{
                                'not-active': txVolume24h !== item.value
                            }"
                        >
                            {{item.label}}
                        </button>
                    </ng-container>

                    <ng-container *ngIf="txVolume24hType === 'custom'">
                        <div class="input-item-box" [class.active]="!!txVolume24h[0]">
                            <nz-input-number
                                [nzFormatter]="formatter"
                                [(ngModel)]="txVolume24h[0]"
                                [nzParser]="parser"
                                class="!w-[108px] input-item"
                                nzPlaceHolder="Min"
                            ></nz-input-number>
                            <span class="suffix-icon">$</span>
                        </div>
                        <div class="input-item-box" [class.active]="!!txVolume24h[1]">
                            <nz-input-number
                                [nzFormatter]="formatter"
                                [(ngModel)]="txVolume24h[1]"
                                [nzParser]="parser"
                                class="!w-[108px] input-item"
                                nzPlaceHolder="Max"
                            ></nz-input-number>
                            <span class="suffix-icon">$</span>
                        </div>
                    </ng-container>

                    <span
                        nz-icon
                        [nzType]="txVolume24hType === 'quickSelect' ? 'antd:v2-edit' : 'antd:v2-reverse'"
                        class="icon-btn select-none"
                        nzTheme="outline"
                        (click)="toggleInputType('txVolume24h')"
                    ></span>

                    <span
                        nz-icon
                        nzType="antd:v2-brush"
                        class="icon-btn"
                        nzTheme="outline"
                        [ngClass]="{
                            'disabled': isEmptyFiled(txVolume24h)
                        }"
                        (click)="revertFieldData('txVolume24h')"
                    ></span>
                </div>
            </div>

            <div class="filter-box-item">
                <div class="font-medium text-black">2. 🌟 Market Cap ($)</div>
                <div class="flex-start-center gap-x-3 mt-3">
                    <ng-container *ngIf="marketCapType === 'quickSelect'">
                        <button
                            *ngFor="let item of marketCapOptions"
                            nz-button
                            nzSize="small"
                            (click)="marketCap = item.value"
                            class="option-item"
                            [ngClass]="{
                                'not-active': marketCap !== item.value
                            }"
                        >
                            {{item.label}}
                        </button>
                    </ng-container>

                    <ng-container *ngIf="marketCapType === 'custom'">
                        <div class="input-item-box" [class.active]="!!marketCap[0]">
                            <nz-input-number
                                [nzFormatter]="formatter"
                                [(ngModel)]="marketCap[0]"
                                [nzParser]="parser"
                                class="!w-[108px] input-item"
                                nzPlaceHolder="Min"
                                [nzMin]="0"
                            ></nz-input-number>
                            <span class="suffix-icon">$</span>
                        </div>
                        <div class="input-item-box" [class.active]="!!marketCap[1]">
                            <nz-input-number
                                [nzFormatter]="formatter"
                                [(ngModel)]="marketCap[1]"
                                [nzParser]="parser"
                                class="!w-[108px] input-item"
                                nzPlaceHolder="Max"
                            ></nz-input-number>
                            <span class="suffix-icon">$</span>
                        </div>
                    </ng-container>

                    <span
                        nz-icon
                        [nzType]="marketCapType === 'quickSelect' ? 'antd:v2-edit' : 'antd:v2-reverse'"
                        class="icon-btn select-none"
                        nzTheme="outline"
                        (click)="toggleInputType('marketCap')"
                    ></span>

                    <span
                        nz-icon
                        nzType="antd:v2-brush"
                        class="icon-btn"
                        nzTheme="outline"
                        [ngClass]="{
                            'disabled': isEmptyFiled(marketCap)
                        }"
                        (click)="revertFieldData('marketCap')"
                    ></span>
                </div>
            </div>

            <div class="filter-box-item">
                <div class="font-medium text-black">3. 🌟 Liquidity ($)</div>
                <div class="flex-start-center gap-x-3 mt-3">
                    <ng-container *ngIf="liquidityType === 'quickSelect'">
                        <button
                            *ngFor="let item of liquidityOptions"
                            nz-button
                            nzSize="small"
                            (click)="liquidity = item.value"
                            class="option-item"
                            [ngClass]="{
                            'not-active': liquidity !== item.value
                        }"
                        >
                            {{item.label}}
                        </button>
                    </ng-container>

                    <ng-container *ngIf="liquidityType === 'custom'">
                        <div class="input-item-box" [class.active]="!!liquidity[0]">
                            <nz-input-number
                                [nzFormatter]="formatter"
                                [(ngModel)]="liquidity[0]"
                                [nzParser]="parser"
                                class="!w-[108px] input-item"
                                nzPlaceHolder="Min"
                                [nzMin]="0"
                            ></nz-input-number>
                            <span class="suffix-icon">$</span>
                        </div>
                        <div class="input-item-box" [class.active]="!!liquidity[1]">
                            <nz-input-number
                                [nzFormatter]="formatter"
                                [(ngModel)]="liquidity[1]"
                                [nzParser]="parser"
                                class="!w-[108px] input-item"
                                nzPlaceHolder="Max"
                                [nzMin]="liquidity[0]"
                            ></nz-input-number>
                            <span class="suffix-icon">$</span>
                        </div>
                    </ng-container>

                    <span
                        nz-icon
                        [nzType]="liquidityType === 'quickSelect' ? 'antd:v2-edit' : 'antd:v2-reverse'"
                        class="icon-btn select-none"
                        nzTheme="outline"
                        (click)="toggleInputType('liquidity')"
                    ></span>

                    <span
                        nz-icon
                        nzType="antd:v2-brush"
                        class="icon-btn"
                        nzTheme="outline"
                        [ngClass]="{
                            'disabled': isEmptyFiled(liquidity)
                        }"
                        (click)="revertFieldData('liquidity')"
                    ></span>
                </div>
            </div>

            <div class="filter-box-item">
                <div class="font-medium text-black">4. 🌟 Holders</div>
                <div class="flex-start-center gap-x-3 mt-3">
                    <ng-container *ngIf="holdersType === 'quickSelect'">
                        <button
                            *ngFor="let item of holdersOptions"
                            nz-button
                            nzSize="small"
                            (click)="holders = item.value"
                            class="option-item"
                            [ngClass]="{
                            'not-active': holders !== item.value
                        }"
                        >
                            {{item.label}}
                        </button>
                    </ng-container>

                    <ng-container *ngIf="holdersType === 'custom'">
                        <div class="input-item-box" [class.active]="!!holders[0]">
                            <nz-input-number
                                [nzFormatter]="formatter"
                                [(ngModel)]="holders[0]"
                                [nzParser]="parser"
                                class="!w-[108px] input-item"
                                nzPlaceHolder="Min"
                                [nzMin]="0"
                                [nzStep]="1"
                            ></nz-input-number>
                        </div>
                        <div class="input-item-box" [class.active]="!!holders[1]">
                            <nz-input-number
                                [nzFormatter]="formatter"
                                [(ngModel)]="holders[1]"
                                [nzParser]="parser"
                                class="!w-[108px] input-item"
                                nzPlaceHolder="Max"
                                [nzMin]="holders[0]"
                                [nzStep]="1"
                            ></nz-input-number>
                        </div>
                    </ng-container>

                    <span
                        nz-icon
                        [nzType]="holdersType === 'quickSelect' ? 'antd:v2-edit' : 'antd:v2-reverse'"
                        class="icon-btn select-none"
                        nzTheme="outline"
                        (click)="toggleInputType('holders')"
                    ></span>

                    <span
                        nz-icon
                        nzType="antd:v2-brush"
                        class="icon-btn"
                        nzTheme="outline"
                        [ngClass]="{
                                'disabled': isEmptyFiled(holders)
                            }"
                        (click)="revertFieldData('holders')"
                    ></span>
                </div>
            </div>

            <div class="filter-box-item">
                <div class="font-medium text-black">5. 🌟 Top 10 Holders</div>
                <div class="flex-start-center gap-x-3 mt-3">
                    <span class="text-2xs text-sub-text">Below</span>
                    <div class="input-item-box" [class.active]="!!top10Holders">
                        <nz-input-number
                            [nzFormatter]="formatter"
                            [(ngModel)]="top10Holders"
                            [nzMin]="0"
                            [nzMax]="100"
                            [nzParser]="parser"
                            class="!w-[185px] input-item"
                            nzPlaceHolder="30"
                        ></nz-input-number>
                        <span class="suffix-icon">%</span>
                    </div>

                    <span
                        nz-icon
                        nzType="antd:v2-brush"
                        class="icon-btn"
                        nzTheme="outline"
                        [ngClass]="{
                            'disabled': !top10Holders
                        }"
                        (click)="revertFieldData('top10Holders')"
                    ></span>
                </div>
            </div>
        </div>

        <ng-container *ngIf="!commonService.isMobile">
            <ng-container *ngTemplateOutlet="btnTpl"></ng-container>
        </ng-container>
    </div>

    <div class="text-error text-sm !mt-3" *ngIf="errorMsg">{{errorMsg}}</div>

    <div class="p-5 rounded-lg border border-line introduction">
        <div class="text-sm font-medium mb-2">How It Works</div>
        <ul>
            <li>Set criteria for each filter</li>
            <li>1 Star is assigned to each filter</li>
            <li>Alerts show🌟when criteria are met</li>
            <li>A Maximum of 5 stars per alert</li>
        </ul>

        <div class="text-sm font-medium mt-4 mb-2">Example</div>
        <div>Filter: High Liquidity (2🌟)</div>
        <ul>
            <li>Liquidity: Min $50K</li>
            <li>24h Volume: Min $100K</li>
        </ul>

        <div class="mt-4">When a token meets these criteria, it gets 2 stars in the alert.</div>
    </div>

    <div *ngIf="commonService.isMobile" class="sticky bottom-0 left-0 w-full">
        <ng-container *ngTemplateOutlet="btnTpl"></ng-container>
    </div>
</div>

<div *ngIf="loading" class="h-[100px]" appLoading [loading]="loading"></div>

<ng-template #btnTpl>
    <div class="bg-white pt-4 w-full flex-between-center gap-x-5">
        <button
            *ngIf="!isEmbedded"
            nz-button
            nzSize="large"
            class="flex-1"
            [ngClass]="{
                    '!text-sub-text !border-line': starLevel === 0,
                }"
            (click)="reset()"
            [disabled]="submitting"
        >
            <div class="font-semibold mr-1">Reset</div>
            <span nz-icon nzType="reload" nzTheme="outline"></span>
        </button>

        <button *ngIf="isEmbedded" nz-button nzSize="large" class="flex-1" [disabled]="submitting" (click)="laterClicked.emit()">
            <div class="font-semibold">Later</div>
        </button>

        <button nz-button nzSize="large" nzType="primary" class="flex-1" (click)="saveSettings()" [nzLoading]="submitting">
            <div class="colorful-text font-semibold">Save</div>
        </button>
    </div>
</ng-template>
