import { TokenMarketCapType } from './meme-dashboard.type';

export function getTokenMarketCapType(marketCap: number): TokenMarketCapType {
    if (marketCap > 10000000) {
        return 'large';
    } else if (marketCap > 100000) {
        return 'medium';
    } else {
        return 'small';
    }
}

export function getHandleTimer(timer: string) {
    const memeRunTimerDate = new Date(timer);
    const now = new Date();
    const diff = memeRunTimerDate.getTime() - now.getTime();
    const seconds = Math.floor(diff / 1000);

    // 如果时间差为负数，说明已经过期
    if (seconds > 0) {
        return '--';
    }

    const minutes = Math.abs(Math.floor(seconds / 60));
    const hours = Math.abs(Math.floor(minutes / 60));
    const days = Math.abs(Math.floor(hours / 24));

    // 根据时间差大小选择合适的单位
    if (days > 0) {
        return `${days}d`;
    } else if (hours > 0) {
        return `${hours}h`;
    } else if (minutes > 0) {
        return `${minutes}m`;
    } else {
        return `${seconds}s`;
    }
}

export function getHandledMemeRunTimer(memeRunTimer: string) {
    const memeRunTimerDate = new Date(memeRunTimer);
    const now = new Date();
    const diff = memeRunTimerDate.getTime() - now.getTime();
    if (diff <= 0) {
        return '--';
    } else if (diff <= 1000 * 60 * 60 * 48) {
        return '< 48h';
    } else if (diff >= 1000 * 60 * 60 * 144) {
        return '> 7d';
    } else {
        return '> 48h';
    }
}

export const SortOrderMap: Record<string, string> = {
    ascend: 'asc',
    descend: 'desc',
};

export function calculateMcPercent(tokenInfo: { price_history: any[]; market_cap: number; [key: string]: any }) {
    const reversedPriceHistory = tokenInfo?.price_history.reverse();
    const currentMC = tokenInfo.market_cap;

    const mcBefore1h = reversedPriceHistory[0]?.market_cap;
    const mcBefore1d = reversedPriceHistory[22]?.market_cap;
    const mcBefore7d = reversedPriceHistory[166]?.market_cap;

    return {
        mc1hPercent: (currentMC - mcBefore1h) / mcBefore1h,
        mc1dPercent: (currentMC - mcBefore1d) / mcBefore1d,
        mc7dPercent: (currentMC - mcBefore7d) / mcBefore7d,
    };
}
