export const environment = {
    production: false,
    socialscanApiUrl: 'https://api-dev.socialscan.io',
    telegram: {
        id: 6453839259,
        botLink: 'https://t.me/hemera_alert_dev_bot', // 用于 Hunter Agent 的 Bot
        socialScanBotLink: 'https://t.me/linxuan99_tma_bot', // 用于登录、用户信息等账号相关的 Bot
    },
    gtag: {
        id: null as string,
    },
};
