import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import en from '@angular/common/locales/en';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouteReuseStrategy } from '@angular/router';
import * as Sentry from '@sentry/angular';
import { en_US, NZ_I18N } from 'ng-zorro-antd/i18n';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { register } from 'swiper/element/bundle';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ProfileFooterComponent } from './modules/my-profile/profile-footer/profile-footer.component';
import { GlobalErrorHandler } from './services/global-error-handler';
import { ReuseService } from './services/route-reuse.service';
import { SharedModule } from './shared/shared.module';
import { AllBadgesComponent } from './standalone-components/all-badges/all-badges.component';
import { HexagonClipperComponent } from './standalone-components/hexagon-clipper/hexagon-clipper.component';
import { MemeSearchComponent } from './standalone-components/meme-search/meme-search.component';
import { NotificationsComponent } from './standalone-components/notifications/notifications.component';
import { SearchResultComponent } from './standalone-components/search-result/search-result.component';
import { SideBarComponent } from './standalone-components/side-bar/side-bar.component';

registerLocaleData(en);
register();

@NgModule({
    declarations: [AppComponent],
    imports: [
        NzLayoutModule,
        SideBarComponent,
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        SharedModule,
        ProfileFooterComponent,
        NotificationsComponent,
        AllBadgesComponent,
        SearchResultComponent,
        HexagonClipperComponent,
        MemeSearchComponent,
    ],
    providers: [
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            useFactory: () => () => {},
            deps: [Sentry.TraceService],
            multi: true,
        },
        { provide: NZ_I18N, useValue: en_US },
        { provide: RouteReuseStrategy, useClass: ReuseService },
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
    ],

    bootstrap: [AppComponent],
})
export class AppModule {}
