<div class="space-y-8 pr-1 h-full">
    <div *ngIf="nzModalData.type === 'watchlist_added'">
        <div class="text-xl font-semibold text-center">Your Watchlist has been added.</div>

        <div class="text-sm text-center text-sub-text mt-2">
            Set up Telegram Alert now to receive alerts about top MEMEs bought by Smart Money in your Watchlist.
        </div>
    </div>

    <div *ngIf="nzModalData.type === 'init_tg_agent'">
        <div class="text-xl font-semibold text-center">Set up Telegram Alert now to receive alerts from your subscribed Watchlist.</div>
    </div>

    <button nz-button nzSize="large" class="w-full" (click)="goToTgAgent()">
        <img src="/assets/imgs/icon/socialmedia/telegram.png" class="w-6 h-6" alt="" />
        <div class="font-semibold ml-2">TG Hunter Agent</div>
    </button>
</div>
