import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MyProfileService } from '../modules/my-profile/my-profile.service';
import { AccountType } from '../shared/types/telegram.type';
import { decodeJWT } from '../shared/utils/jwt.util';
import { AuthTokenService } from './auth-token.service';
import { CommonService } from './common.service';
import { TelegramService } from './telegram.service';
import { WalletStatusService } from './wallet-status.service';

/**
 * 统筹 wallet 跟 tg 的 user 信息
 */
@Injectable({
    providedIn: 'root',
})
export class UserService {
    public get userAvatar() {
        if (this.isOnlyTg) {
            return this.tgService.tgUserData?.photo_url || '/assets/imgs/icon/user.png';
        } else {
            if (this.myProfileService.userProfile?.profile_image) {
                return this.myProfileService.userProfile?.profile_image;
            } else if (this.walletStatusService.walletAddress) {
                return this.commonService.getAddressIcon(this.walletStatusService.walletAddress);
            } else {
                return '/assets/imgs/icon/user.png';
            }
        }
    }

    public get userName() {
        if (this.isOnlyTg) {
            return this.tgService.tgUserData?.username || 'N/A';
        } else {
            return this.commonService.getAddrName(this.myProfileService.userProfile) || this.walletStatusService.walletAddress;
        }
    }

    get isAuthenticated() {
        return this.walletStatusService.isWalletConnected || this.tgService.isTGLogin || this.tgService.isInTelegramWebApp;
    }

    // 当前登录账号类型
    get accountType(): AccountType {
        if (this.walletStatusService.isWalletConnected && this.tgService.isInTelegramWebApp) {
            return AccountType.TG_WALLET;
        }

        if (this.tgService.isInTelegramWebApp) {
            return AccountType.ONLY_TG;
        }

        if (this.walletStatusService.isWalletConnected) {
            return AccountType.ONLY_WALLET;
        }

        return AccountType.NOT_CONNECTED;
    }

    // Utils
    get isOnlyTg() {
        return this.accountType === AccountType.ONLY_TG;
    }

    constructor(
        private walletStatusService: WalletStatusService,
        private tgService: TelegramService,
        private myProfileService: MyProfileService,
        private authTokenService: AuthTokenService,
        private router: Router,
        private commonService: CommonService
    ) {}

    getUserID() {
        if (this.isAuthenticated) {
            return this.myProfileService.userProfile?.id || decodeJWT(this.authTokenService.tokenStr).user_id;
        }

        return null;
    }

    logout(pathAfterLogout?: string[]) {
        this.walletStatusService.clearCurrentWallet();
        this.myProfileService.clearUserProfile();
        this.tgService.clearTGAuthData();
        this.authTokenService.clearAuthToken();

        if (pathAfterLogout) {
            this.router.navigate(pathAfterLogout);
        } else {
            if (window.location.pathname?.startsWith('/meme-dashboard')) {
                window.location.reload();
            } else {
                window.location.href = '/';
            }
        }
    }
}
