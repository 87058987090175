import { ErrorHandler, Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular';
import { environment } from '@src/environments/environment';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    private sentryErrorHandler: any;

    constructor() {
        if (environment.production) {
            // 创建 Sentry error handler
            this.sentryErrorHandler = Sentry.createErrorHandler({
                showDialog: false,
            });
        }
    }

    handleError(error: any): void {
        // 处理 chunk 加载失败的情况
        const chunkFailedMessage = /Loading chunk [^\s]+ failed/;
        if (chunkFailedMessage.test(error.message)) {
            window.location.reload();
        } else {
            if (environment.production) {
                // 生产环境使用 Sentry
                this.sentryErrorHandler?.handleError(error);
            } else {
                // 开发环境只打印错误
                console.error('An error occurred:', error);
            }
        }
    }
}
