import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TGUnSafeUserData } from '@shared/types/telegram.type';
import { LOCAL_STORAGE_KEY_ENUM } from '../shared/types/storage.type';
import { getTGDataFromWebApp, getTGSafeDataFromWebApp } from '../shared/utils/tg.util';
import { CommonService } from './common.service';
import { TelegramApiService } from './telegram-api.service';

/**
 * 这个服务只处理前端的 Telegram 所有逻辑，不负责 API 通信部分
 */
@Injectable({
    providedIn: 'root',
})
export class TelegramService {
    tgData: TGUnSafeUserData;
    tgSafeData: any;

    // 可以通过/tg-login?code= 链接登录，对于这种，标记为 TGLogin
    isTGLogin = false;

    // TG WebApp 进入的程序，会自动在本地嵌入用户信息
    get tgUserData() {
        return this.tgData?.user;
    }

    // 是否在TG WebApp中，即小程序
    get isInTelegramWebApp() {
        return !!this.tgUserData;
    }

    constructor(private router: Router, private tgAPIService: TelegramApiService, private commonService: CommonService) {
        if (this.commonService.checkIfInBrowserPlatform()) {
            this.initialize();
            this.setupRouterChangeMonitor();
            this.checkIfLoginWithTG();
        }
    }

    // 使用 TG WebApp 登录（注：跟使用login with TG 不一样）
    loginWithTG(code?: string) {
        return this.tgAPIService.loginWithTG(this.tgSafeData, code);
    }

    private async initialize(): Promise<void> {
        // 等待 SDK 加载
        while (!this.isTelegramWebAppAvailable()) {
            await new Promise(resolve => setTimeout(resolve, 100));
        }

        this.setupUserInfo();
        this.setupBackButton();
    }

    private isTelegramWebAppAvailable(): boolean {
        return !!window.Telegram?.WebApp;
    }

    /**
     * 设置 Telegram WebApp 的用户信息
     * 存放在 localStorage 中原因是，如果用户在 Telegram WebApp 中，刷新页面，用户信息会丢失
     * 这样在刷新后，可以重新从 localStorage 中获取用户信息
     */
    private async setupUserInfo() {
        this.tgData = getTGDataFromWebApp();
        this.tgSafeData = getTGSafeDataFromWebApp();

        if (!this.tgUserData) {
            const cachedTgData = localStorage.getItem(LOCAL_STORAGE_KEY_ENUM.SOCIALSCAN_TG_WEBAPP_DATA);
            const cachedTgSafeData = localStorage.getItem(LOCAL_STORAGE_KEY_ENUM.SOCIALSCAN_TG_SAFE_DATA);
            if (cachedTgData) {
                this.tgData = JSON.parse(cachedTgData) as TGUnSafeUserData;
            }
            if (cachedTgSafeData) {
                this.tgSafeData = JSON.parse(cachedTgSafeData);
            }
        } else {
            localStorage.setItem(LOCAL_STORAGE_KEY_ENUM.SOCIALSCAN_TG_WEBAPP_DATA, JSON.stringify(this.tgData));
            localStorage.setItem(LOCAL_STORAGE_KEY_ENUM.SOCIALSCAN_TG_SAFE_DATA, JSON.stringify(this.tgSafeData));
        }
    }

    /**
     * 设置 Telegram WebApp 的返回按钮功能
     */
    private setupBackButton() {
        window.Telegram.WebApp.BackButton.onClick(() => this.router.navigate(['/my-profile']));
    }

    /**
     * TODO: 这部分或许是无效的代码，需要确认是否移除
     * 设置路由变化监听器，用来控制 Telegram WebApp 的返回按钮功能
     * 当用户从其他页面返回时，显示返回按钮
     * 当用户在 /my-profile 页面时，隐藏返回按钮
     */
    private setupRouterChangeMonitor() {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                // if not in /my-profile page, show back button
                if (!event.url.startsWith('/my-profile')) {
                    window.Telegram.WebApp.BackButton.show();
                } else {
                    window.Telegram.WebApp.BackButton.hide();
                }
            }
        });
    }

    // 检查是否通过 Login with TG 登录
    private checkIfLoginWithTG() {
        const loginWithTG = localStorage.getItem(LOCAL_STORAGE_KEY_ENUM.LOGIN_WITH_TG) === 'yes';
        if (loginWithTG) {
            this.isTGLogin = true;
        }
    }

    clearTGAuthData() {
        localStorage.removeItem(LOCAL_STORAGE_KEY_ENUM.LOGIN_WITH_TG);
        this.isTGLogin = false;
    }
}
