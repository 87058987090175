import { Injectable } from '@angular/core';
import { MyProfileService } from '@src/app/modules/my-profile/my-profile.service';
import { UserService } from '@src/app/services/user.service';

@Injectable({
    providedIn: 'root',
})
export class ProfileGuard {
    constructor(private myProfileService: MyProfileService, private userService: UserService) {}

    async canActivate() {
        if (!this.userService.isAuthenticated) {
            return false;
        }

        if (this.myProfileService.userProfile) {
            return true;
        }

        return this.myProfileService.pullNewUserProfile();
    }
}
