import { Injectable } from '@angular/core';
import { CommonService } from '@src/app/services/common.service';
import { RequestService } from '@src/app/services/request.service';
import { PageApiData } from '@src/app/shared/types/common.type';
import { ChainEnum } from '@src/app/shared/types/wallet-chain.type';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import {
    AddWatchResult,
    HolderHolding,
    HolderTransaction,
    MemeAgentUserSettings,
    MemeHolderProfile,
    MemeToken,
    MemeTokenHolder,
    MemeTokenProfile,
    MemeTokenTransaction,
    SearchedToken,
    TokenMarketCapType,
    TrackOption,
    WatchList,
    WatchlistAgentInfo,
    WatchlistTGAlert,
} from './meme-dashboard.type';
import { AddWatchlistModalComponent } from './pages/my-watchlist/add-watchlist-modal/add-watchlist-modal.component';

@Injectable()
export class MemeDashboardService {
    constructor(private drawerService: NzDrawerService, private requestService: RequestService, private commonService: CommonService) {}

    showAddWatchlistDialog(onlyUpdateAgentInfo = false) {
        return this.drawerService.create({
            nzTitle: null,
            nzContent: AddWatchlistModalComponent,
            nzPlacement: this.commonService.isMobile ? 'bottom' : 'right',
            nzWidth: 480,
            nzHeight: this.commonService.isMobile ? '75vh' : 'auto',
            nzClosable: this.commonService.isMobile ? true : false,
            nzContentParams: {
                onlyUpdateAgentInfo,
            },
        });
    }

    getWatchlist() {
        return this.requestService.sendRequest<WatchList>({
            method: 'GET',
            url: '/v1/socialscan/meme-dashboard/watchlist',
        });
    }

    changeAddressesFromWatchlist(data: { address_to_add?: string[]; address_to_delete?: string[] }) {
        return this.requestService.sendRequest<{ data: AddWatchResult[] }>({
            method: 'POST',
            data,
            url: '/v1/socialscan/meme-dashboard/watchlist/address',
        });
    }

    addAddressToWatchlist(address: string) {
        return this.requestService.sendRequest<{ data: AddWatchResult[] }>({
            method: 'POST',
            data: { address_to_add: [address] },
            url: '/v1/socialscan/meme-dashboard/watchlist/address',
        });
    }

    getMemeTokenTreemapData(timerange: string) {
        return this.requestService.sendRequest<PageApiData<MemeToken>>({
            method: 'GET',
            url: `/v1/socialscan/meme-dashboard/smartmoney/treemap?timerange=${timerange}`,
        });
    }

    getMemeTokenTrendingData(data: {
        timerange: '1h' | '4h' | '1d' | '7d';
        market_cap: TokenMarketCapType[];
        search: string;
        page: number;
        size: number;
        sort_by: string;
        sort_order: string | 'ascend' | 'descend' | null;
        filters: {
            key: string;
            operator: '>' | '<';
            value: number;
        }[];
    }) {
        return this.requestService.sendRequest<PageApiData<MemeToken>>({
            method: 'POST',
            url: `/v1/socialscan/meme-dashboard/tokens`,
            data,
        });
    }

    getTokenDetail(chainName: ChainEnum, address: string) {
        return this.requestService.sendRequest<MemeTokenProfile>({
            method: 'GET',
            url: `/v1/socialscan/meme-dashboard/token/${chainName}/${address}/profile`,
        });
    }

    getTokenHolders(chainName: ChainEnum, address: string) {
        return this.requestService.sendRequest<{ chain_name: ChainEnum; data: MemeTokenHolder[]; token_address: string; total_supply: number }>({
            method: 'GET',
            url: `/v1/socialscan/meme-dashboard/token/${chainName}/${address}/holders`,
        });
    }

    getTokenTransactions(
        chainName: ChainEnum,
        address: string,
        query: {
            timerange: '5m' | '1h' | '24h';
            type: 'top_holders' | 'smart_buy' | 'smart_sell' | 'all';
            tx_type: 'all' | 'buy' | 'sell';
            last_block_timestamp?: string;
        }
    ) {
        const searchParams = new URLSearchParams();
        Object.entries(query)
            .filter(([_, value]) => !!value)
            .forEach(([key, value]) => {
                searchParams.set(key, String(value));
            });

        const url = `/v1/socialscan/meme-dashboard/token/${chainName}/${address}/transactions?${searchParams.toString()}`;

        return this.requestService.sendRequest<{
            data: MemeTokenTransaction[];
            token_address: string;
            chain_name: ChainEnum;
            last_block_timestamp: string;
            total: number;
        }>({
            method: 'GET',
            url,
        });
    }

    addLabel(address: string, label: string) {
        return this.requestService.sendRequest({
            method: 'POST',
            url: `/v1/socialscan/meme-dashboard/user/label`,
            data: { address, label },
        });
    }

    getHolderProfile(address: string) {
        return this.requestService.sendRequest<MemeHolderProfile>({
            method: 'GET',
            url: `/v1/socialscan/meme-dashboard/address/${address}/profile`,
        });
    }

    getHolderHoldings(address: string) {
        return this.requestService.sendRequest<{ address: string; data: HolderHolding[]; total: number }>({
            method: 'GET',
            url: `/v1/socialscan/meme-dashboard/address/${address}/holdings`,
        });
    }

    getHolderTransactions(address: string, query: { last_block_timestamp: string }) {
        const searchParams = new URLSearchParams();
        Object.entries(query)
            .filter(([_, value]) => !!value)
            .forEach(([key, value]) => {
                searchParams.set(key, String(value));
            });

        const url = `/v1/socialscan/meme-dashboard/address/${address}/transactions?${searchParams.toString()}`;

        return this.requestService.sendRequest<{ address: string; data: HolderTransaction[]; last_block_timestamp: string }>({
            method: 'GET',
            url,
        });
    }

    searchToken(search: string) {
        return this.requestService.sendRequest<{
            data: SearchedToken[];
            total: number;
        }>({
            method: 'GET',
            url: `/v1/socialscan/meme-dashboard/token/search?query=${search}`,
        });
    }

    getMyWatchListTreemapData(timerange: string) {
        return this.requestService.sendRequest<PageApiData<MemeToken>>({
            method: 'GET',
            url: `/v1/socialscan/meme-dashboard/watchlist/treemap?timerange=${timerange}`,
        });
    }

    getWatchlistTreemapData(watchlist_id: number, timerange: string) {
        return this.requestService.sendRequest<PageApiData<MemeToken>>({
            method: 'GET',
            url: `/v1/socialscan/meme-dashboard/watchlist/${watchlist_id}/treemap?timerange=${timerange}&size=20`,
        });
    }

    getMemeAgentUserSettings() {
        return this.requestService.get<MemeAgentUserSettings>('/v1/socialscan/meme-dashboard/user/setting');
    }

    setMemeAgentUserSettings(data: {
        agent_settings?: {
            market_cap: [number, number];
            tx_volume_24h: [number, number];
            liquidity: [number, number];
            pool_age: [number, number];
            disabled_track_options: TrackOption[];
            top_10_holders_percentage: number;
            smart_buy_range: [number, number];
            smart_watchlist_simple_buy_range: [number, number];
            star_level: number;
        };
        agent_reminder?: boolean;
        my_watchlist_settings?: {
            market_cap: [number, number];
            tx_volume_24h: [number, number];
            liquidity: [number, number];
            holders: [number, number];
            top_10_holders_percentage: number;
        };
    }) {
        return this.requestService.post<MemeAgentUserSettings>('/v1/socialscan/meme-dashboard/user/setting', data);
    }

    createOrUpdateMyWatchListAgentInfo(data: { name?: string; introduction?: string; logo_url?: string; allow_subscription?: boolean }) {
        return this.requestService.post<WatchlistAgentInfo>('/v1/socialscan/meme-dashboard/watchlist', data);
    }

    getMyWatchListAgentInfo() {
        return this.requestService.get<any>('/v1/socialscan/meme-dashboard/watchlist');
    }

    getMarketplaceWatchlist(params?: { page?: number; size?: number; sort?: 'pnl_7d' | 'subscriber_count' }) {
        return this.requestService.get<PageApiData<WatchlistAgentInfo>>('/v1/socialscan/meme-dashboard/marketplace/list', params);
    }

    subscribeWatchlist(watchlist_id: number) {
        return this.requestService.post('/v1/socialscan/meme-dashboard/watchlist/subscribe', { watchlist_id });
    }

    unsubscribeWatchlist(watchlist_id: number) {
        return this.requestService.post('/v1/socialscan/meme-dashboard/watchlist/unsubscribe', { watchlist_id });
    }

    getMySubscribedWatchlist() {
        return this.requestService.get<PageApiData<WatchlistAgentInfo>>('/v1/socialscan/meme-dashboard/marketplace/subsriptions');
    }

    getWatchlistAgentDetail(watchlist_id: number) {
        return this.requestService.get<WatchlistAgentInfo>(`/v1/socialscan/meme-dashboard/watchlist/${watchlist_id}/profile`);
    }

    getRecentWatchlistTGAlerts(watchlist_id: number) {
        return this.requestService.get<PageApiData<WatchlistTGAlert>>(`/v1/socialscan/meme-dashboard/watchlist/${watchlist_id}/alert`);
    }

    getUserWatchlistTGAlerts(option: any, page: number, size: number) {
        return this.requestService.get<PageApiData<WatchlistTGAlert>>('/v1/socialscan/meme-dashboard/watchlist/alert', { option, page, size });
    }
}
