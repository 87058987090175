import { formatPercent } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthTokenService } from '@src/app/services/auth-token.service';
import { CommonService } from '@src/app/services/common.service';
import { WalletStatusService } from '@src/app/services/wallet-status.service';
import { formatNumberWithPrefix } from '@src/app/shared/utils/number.util';
import { environment } from '@src/environments/environment';
import clsx from 'clsx';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { NzModalService } from 'ng-zorro-antd/modal';
import { BehaviorSubject, filter, take } from 'rxjs';
import { MyProfileService } from '../my-profile/my-profile.service';
import { HunterAgentSettingsComponent } from './components/hunter-agent-settings/hunter-agent-settings.component';
import { MemeStarSettingsComponent } from './components/meme-star-settings/meme-star-settings.component';
import { WatchlistAgentSetupGuideComponent } from './components/watchlist-agent-setup-guide/watchlist-agent-setup-guide.component';
import { MemeDashboardService } from './meme-dashboard.service';
import { MemeAgentUserSettings, MEMEDashboardSupportedChain, TokenMarketCapType, WatchList, WatchlistAgentInfo } from './meme-dashboard.type';

@Injectable()
export class MemeDashboardStatusService {
    readonly WatchLimitCount = 50;

    readonly shownDetailedTreemap$ = new BehaviorSubject<TokenMarketCapType>(null);
    readonly newAddedWatchlistCount$ = new BehaviorSubject<number>(0);

    private selectedChain$ = new BehaviorSubject<MEMEDashboardSupportedChain>('base');

    memeAgentUserSettings$ = new BehaviorSubject<MemeAgentUserSettings>(null);

    myWatchlistWallets$ = new BehaviorSubject<WatchList['address_list']>(null);

    mySubscribedWatchlistAgents$ = new BehaviorSubject<WatchlistAgentInfo[]>([]);
    mySubscribedWatchlistAgentIDs$ = new BehaviorSubject<number[]>([]);

    myWatchlistAgentInfo$ = new BehaviorSubject<WatchlistAgentInfo>(null);

    get memeAgentUserSettings() {
        return this.memeAgentUserSettings$.value;
    }

    get memeAgentInitialized() {
        return this.memeAgentUserSettings?.agent_initialized;
    }

    get selectedChain() {
        return this.selectedChain$.value;
    }

    get shownDetailedTreemap() {
        return this.shownDetailedTreemap$.value;
    }

    get chainImg() {
        if (this.selectedChain === 'base') {
            return '/assets/imgs/chains/base.png';
        } else if (this.selectedChain === 'bsc') {
            return '/assets/imgs/chains/bnb-v3.png';
        } else if (this.selectedChain === 'story') {
            return '/assets/imgs/chains/story-rounded.svg';
        } else if (this.selectedChain === 'solana') {
            return '/assets/imgs/chains/solana-rounded.png';
        }

        return '';
    }

    constructor(
        private memeDashboardService: MemeDashboardService,
        private modalService: NzModalService,
        private commonService: CommonService,
        private drawerService: NzDrawerService,
        private walletStatusService: WalletStatusService,
        private myProfileService: MyProfileService,
        private router: Router,
        private authTokenService: AuthTokenService
    ) {
        this.authTokenService.tokenStr$
            .pipe(
                filter(item => !!item),
                take(1)
            )
            .subscribe(auth => {
                // 获取我的 meme agent 设置
                this.fetchNewMemeAgentUserSettings();

                // 获取我的 watchlist 地址列表
                this.fetchMyWatchlistWallets();

                // 监听我的 watchlist 变化
                this.setupMyWatchlistChangeSubscription();

                // 获取我的订阅的 watchlist
                this.fetchMySubscribedWatchlistAgents();
            });
    }

    setupMyWatchlistChangeSubscription() {
        this.mySubscribedWatchlistAgents$.subscribe(res => this.mySubscribedWatchlistAgentIDs$.next(res.map(item => item.id)));
    }

    setShownDetailedTreemap(value: TokenMarketCapType) {
        this.shownDetailedTreemap$.next(value);
    }

    setNewAddedWatchlistCount(value: number) {
        this.newAddedWatchlistCount$.next(value);
    }

    setSelectedChain(chain: MEMEDashboardSupportedChain) {
        this.selectedChain$.next(chain);

        /**
         * TODO:
         * 1. save to local storage
         * 2. refresh page
         */

        // localStorage.setItem(LOCAL_STORAGE_KEY_ENUM.xxx, chain);
        location.reload();
    }

    fetchNewMemeAgentUserSettings() {
        this.memeDashboardService.getMemeAgentUserSettings().subscribe(res => this.memeAgentUserSettings$.next(res));
    }

    fetchMyWatchlistWallets() {
        this.memeDashboardService
            .getWatchlist()
            .then(data => this.myWatchlistWallets$.next(data.address_list))
            .catch(err => {
                console.error(err);
                this.myWatchlistWallets$.next([]);
            });
    }

    fetchMySubscribedWatchlistAgents() {
        this.memeDashboardService.getMySubscribedWatchlist().subscribe({
            next: res => this.mySubscribedWatchlistAgents$.next(res.data),
            error: err => {
                console.error(err);
                this.mySubscribedWatchlistAgents$.next([]);
            },
        });
    }

    showTGAgentSetupGuide(type: 'watchlist_added' | 'init_tg_agent') {
        this.modalService.create({
            nzTitle: null,
            nzContent: WatchlistAgentSetupGuideComponent,
            nzWidth: 500,
            nzCentered: true,
            nzFooter: null,
            nzMaskClosable: false,
            nzData: {
                type,
            },
        });
    }

    showHunterAgentSettingsDialog(type: 'watchlist_added' | 'init_tg_agent' = 'init_tg_agent') {
        if (!this.memeAgentInitialized) {
            this.showTGAgentSetupGuide(type);
        } else {
            this.drawerService.create({
                nzTitle: null,
                nzContent: HunterAgentSettingsComponent,
                nzPlacement: this.commonService.isMobile ? 'bottom' : 'right',
                nzClosable: this.commonService.isMobile ? true : false,
                nzWidth: 500,
                nzHeight: this.commonService.isMobile ? '640px' : 'auto',
            });
        }
    }

    showMemeStarSettingsDialog() {
        this.drawerService.create({
            nzTitle: null,
            nzContent: MemeStarSettingsComponent,
            nzPlacement: this.commonService.isMobile ? 'bottom' : 'right',
            nzClosable: this.commonService.isMobile ? true : false,
            nzWidth: 500,
            nzHeight: this.commonService.isMobile ? '640px' : 'auto',
        });
    }

    addAddressToWatchlist(address: string) {
        return this.memeDashboardService
            .addAddressToWatchlist(address)
            .then(({ data }) => {
                if (data.some(item => item.is_added === false && item.error_type === 'watchlist_full')) {
                    this.commonService.error(`your watchlist has reached its limit of ${this.WatchLimitCount}`);
                    return false;
                } else if (data.every(item => !item.is_added)) {
                    return false;
                }

                return true;
            })
            .catch(err => this.commonService.errorMessageByResponse(err));
    }

    showInsufficientPointsModal(agentInfo: WatchlistAgentInfo) {
        const subText = clsx('text-sub-text text-center text-sm');
        const isWatchlistCreated = this.walletStatusService.userProfile?.watchlist.address_added;

        const ref = this.modalService.create({
            nzTitle: 'Insufficient Points Balance',
            nzContent: `<div class="${subText}">${
                isWatchlistCreated ? 'Share your Watchlist to earn more points!' : 'Create and Share your Watchlist to earn more points'
            }</div>`,
            nzCentered: true,
            nzFooter: [
                isWatchlistCreated
                    ? {
                          label: 'Share to X',
                          type: 'primary',
                          onClick: () => {
                              this.shareToTwitter(agentInfo);
                              ref.destroy();
                          },
                      }
                    : {
                          label: 'Create My Watchlist',
                          type: 'primary',
                          onClick: () => {
                              this.router.navigate(['/meme-dashboard/my-watchlist']);
                              ref.destroy();
                          },
                      },
            ],
        });
    }

    shareToTwitter(agentInfo: WatchlistAgentInfo) {
        const queryParams = `invitation_code=${this.myProfileService.referralCode}&wid=${this.walletStatusService.userProfile?.watchlist?.id}&show_login=1`;
        const pnl7DPercent = formatPercent(agentInfo.stats?.pnl_7d / agentInfo.stats?.buy_usd_7d || 0, 'en-US', '1.0-2');
        const winRate = formatPercent(agentInfo.stats?.win_rate || 0, 'en-US', '1.0-2');
        const text = encodeURIComponent(`🚀🚀 Discover your Next 1000x MEME Coins with My Smart Watchlist!
${this.walletStatusService.userProfile?.watchlist?.name}
7D PnL ${formatNumberWithPrefix(agentInfo.stats?.pnl_7d || 0, '$')}(${pnl7DPercent})
Win Rate ${winRate}
Smart Holders ${agentInfo.address_count}

#MEMECoin #SocialScan #Agent
https://${environment.production ? '' : 'dev.'}socialscan.io/home?${queryParams}`);
        const url = `https://twitter.com/intent/tweet?text=${text}`;
        window.open(url, '_blank');
    }
}
