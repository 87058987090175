import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class GlobalStatusService {
    followStatusChange$ = new BehaviorSubject<{ status: boolean; address?: string }>(null);

    sideBarCollapsed$ = new BehaviorSubject<boolean>(false);

    updateFollowStatus(status: boolean, address: string) {
        this.followStatusChange$.next({ status, address });
    }
}
