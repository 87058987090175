import { Injectable } from '@angular/core';
import { AppKit, createAppKit } from '@reown/appkit';
import { EthersAdapter } from '@reown/appkit-adapter-ethers';
import { arbitrum, avalanche, baseSepolia, blast, bsc, linea, mainnet, mantle, optimism, polygon, scroll, zksync } from '@reown/appkit/networks';
import { useAppKitProvider } from '@reown/appkit/react';
import { LOCAL_STORAGE_KEY_ENUM } from '@shared/types/storage.type';
import { WalletEnum, WalletIconMap, WalletsEnableConnect } from '@shared/types/wallet-chain.type';
import { jwtDecode } from 'jwt-decode';
import { BehaviorSubject } from 'rxjs';
import { MyProfileService } from '../modules/my-profile/my-profile.service';
import { MyProfile } from '../shared/types/address.type';
import { isMobileBrowser } from '../shared/utils/browser';
import { AuthTokenService } from './auth-token.service';
import { CommonService } from './common.service';
import { RequestService } from './request.service';

/**
 * 负责所有 Wallet 相关的逻辑
 */
@Injectable({
    providedIn: 'root',
})
export class WalletStatusService {
    wallets = WalletsEnableConnect;
    walletIconMap = WalletIconMap;

    currentWallet = new BehaviorSubject<{
        wallet: WalletEnum;
        walletAddress: string;
    }>(null);

    web3Modal: AppKit;

    // tokenExp: number; // milliseconds
    userId: string;
    signUpCompleted: boolean;

    selectedWalletChainId: string = null;

    disconnecting = false;

    get walletType() {
        return this.currentWallet.getValue()?.wallet;
    }

    get isWalletConnected() {
        return !!this.currentWallet.getValue()?.walletAddress;
    }

    get walletAddress() {
        return this.currentWallet.getValue()?.walletAddress;
    }

    get wallet() {
        return this.currentWallet.getValue()?.wallet;
    }

    get currentWalletIcon() {
        return this.walletIconMap.get(this.currentWallet.getValue()?.wallet);
    }

    // User Profile
    get userProfile$() {
        return this.myProfileService.userProfile$;
    }
    get userProfile() {
        return this.myProfileService.userProfile$.getValue();
    }
    set userProfile(value: MyProfile) {
        this.myProfileService.userProfile$.next(value);
    }

    constructor(
        private requestService: RequestService,
        private commonService: CommonService,
        private myProfileService: MyProfileService,
        private authTokenService: AuthTokenService
    ) {
        if (this.commonService.checkIfInBrowserPlatform()) {
            this.generateWeb3Modal();
            this.setupInitialState();
            this.commonService.setAddressIconMap([this.walletAddress]);
        }
    }

    // 如果是从 TG 登录同步来的wallet信息，没有signature
    updateCurrentWallet(data: { wallet: WalletEnum; walletAddress: string }) {
        localStorage.setItem(
            LOCAL_STORAGE_KEY_ENUM.CURRENT_WALLET,
            JSON.stringify({
                wallet: data.wallet,
                walletAddress: data.walletAddress,
            })
        );
        this.currentWallet.next({
            wallet: data.wallet,
            walletAddress: data.walletAddress,
        });

        this.commonService.setAddressIconMap([data.walletAddress]);
    }

    async clearCurrentWallet() {
        localStorage.removeItem(LOCAL_STORAGE_KEY_ENUM.CURRENT_WALLET);

        if (this.wallet == WalletEnum.WALLET_CONNECT) {
            try {
                await this.web3Modal.disconnect();
            } catch (err) {
                console.error(err);
            }
        }
        this.currentWallet.next(null);
    }

    setupInitialState() {
        const walletData = localStorage.getItem(LOCAL_STORAGE_KEY_ENUM.CURRENT_WALLET);

        if (walletData) {
            try {
                const { wallet, walletAddress } = JSON.parse(walletData);
                this.currentWallet.next({
                    wallet,
                    walletAddress,
                });
            } catch (er) {
                console.warn('Invalid wallet data in session storage');
            }
        }
    }

    completeRegistration(data: { user_id: string; invitation_code: string; email?: string }) {
        return this.requestService
            .sendRequest<{ auth: string }>({
                method: 'POST',
                url: '/v1/socialscan/user/complete_registration',
                data,
            })
            .then(res => {
                this.signUpCompleted = true;
                this.authTokenService.setAuthToken(res.auth);
            });
    }

    async generateWeb3Modal() {
        const projectId = 'c41d849166d6db9d1cea7fc2603554b6';

        const metadata = {
            name: 'SocialScan',
            description: 'SocialScan',
            url: 'https://socialscan.io',
            icons: ['https://socialscan.io/favicon.ico'],
        };

        this.web3Modal = createAppKit({
            adapters: [new EthersAdapter()],
            defaultNetwork: mainnet,
            allowUnsupportedChain: true,
            networks: [mainnet, bsc, polygon, arbitrum, optimism, avalanche, zksync, linea, blast, scroll, mantle, baseSepolia],
            metadata,
            projectId,
            features: {
                analytics: true, // Optional - defaults to your Cloud configuration
                socials: false,
                email: false,
            },
            themeMode: 'light',
        });
    }

    refreshUserToken(data: { wallet_address: string; message: string; signature: string }) {
        return this.requestService.sendRequest<{ auth: string; sdn_token: string; registration_complete: boolean; user_id: string }>({
            method: 'POST',
            url: '/v1/socialscan/user/login',
            data,
        });
    }

    decodeJWT(token: string): any {
        return jwtDecode(token);
    }

    detectWalletsBasedOnDevice() {
        if (isMobileBrowser()) {
            if (window.ethereum && (window.ethereum as any).isMetaMask) {
                return this.wallets.filter(item => item.name === WalletEnum.META_MASK);
            }

            if (window.ethereum && ((window.ethereum as any).isTrust || (window.ethereum as any).isTrustWallet)) {
                return this.wallets.filter(item => item.name === WalletEnum.TRUST_WALLET);
            }

            if (window.Telegram.WebApp?.initDataUnsafe?.user?.username) {
                return this.wallets.filter(item => item.name === WalletEnum.WALLET_CONNECT);
            }

            return [WalletEnum.WALLET_CONNECT, WalletEnum.TRUST_WALLET].map(item => this.wallets.find(wallet => wallet.name === item));
        } else {
            return this.wallets;
        }
    }

    getTrustWalletFromWindow() {
        const isTrustWallet = (ethereum: any) => {
            // Identify if Trust Wallet injected provider is present.
            const trustWallet = !!ethereum.isTrust;

            return trustWallet;
        };

        const injectedProviderExist = typeof window !== 'undefined' && typeof window.ethereum !== 'undefined';

        if (!injectedProviderExist) {
            return null;
        }

        if (isTrustWallet(window.ethereum)) {
            return window.ethereum;
        }

        if ((window.ethereum as any)?.providers) {
            return (window.ethereum as any).providers.find(isTrustWallet) ?? null;
        }

        return (window as any)['trustwallet'] ?? null;
    }

    getProvider(chainId?: number) {
        if (this.wallet == WalletEnum.TRUST_WALLET) {
            return this.getTrustWalletFromWindow();
        }

        if (this.wallet == WalletEnum.WALLET_CONNECT) {
            return this.web3Modal.getWalletProvider() || (useAppKitProvider('eip155')?.walletProvider as any);
        }

        if (this.wallet === WalletEnum.META_MASK) {
            return (window.ethereum as any)?.providers?.find((item: any) => item.isMetaMask) || window.ethereum;
        }

        return window.ethereum;
    }

    generateRandomHex(size: number) {
        let result = '';
        for (let i = 0; i < size; i++) {
            result += Math.floor(Math.random() * 256)
                .toString(16)
                .padStart(2, '0');
        }
        return result;
    }

    getMessageToSign() {
        return `Login with this account\n\ntime: ${new Date().toISOString()}\n${this.generateRandomHex(32)}`;
    }
}
