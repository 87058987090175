import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SharedModule } from '@shared/shared.module';
import { CommonService } from '@src/app/services/common.service';
import { UserService } from '@src/app/services/user.service';
import { WalletStatusService } from '@src/app/services/wallet-status.service';
import { LOCAL_STORAGE_KEY_ENUM } from '@src/app/shared/types/storage.type';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
    selector: 'app-invitation-email-popup',
    standalone: true,
    imports: [CommonModule, SharedModule],
    templateUrl: './invitation-email-popup.component.html',
    styleUrls: ['./invitation-email-popup.component.less'],
})
export class InvitationEmailPopupComponent implements OnInit {
    confirming = false;
    invitationCode = '';
    email: string;

    invalidInvitationCode = false;
    invalidMsg: string;

    constructor(
        private walletStatusService: WalletStatusService,
        private modalRef: NzModalRef,
        private commonService: CommonService,
        private userService: UserService,
        private router: Router
    ) {}

    ngOnInit() {
        this.invitationCode = localStorage.getItem(LOCAL_STORAGE_KEY_ENUM.SOCIALSCAN_INVITATION_CODE) || null;
    }

    completeSignUp() {
        this.confirming = true;
        this.walletStatusService
            .completeRegistration({
                user_id: this.userService.getUserID(),
                invitation_code: this.invitationCode,
                email: this.email,
            })
            .then(() => {
                this.modalRef.close();
                this.redirectAfterLogin();
            })
            .catch(err => {
                if (err.status === 400) {
                    this.invalidInvitationCode = true;
                    this.invalidMsg = err.body.message;
                } else {
                    this.commonService.errorMessageByResponse(err);
                }
            })
            .finally(() => (this.confirming = false));
    }

    redirectAfterLogin() {
        const wid = localStorage.getItem(LOCAL_STORAGE_KEY_ENUM.SOCIALSCAN_WID);
        const pathAfterLogin = localStorage.getItem(LOCAL_STORAGE_KEY_ENUM.PATH_AFTER_LOGIN);

        if (pathAfterLogin) {
            localStorage.removeItem(LOCAL_STORAGE_KEY_ENUM.PATH_AFTER_LOGIN);
            this.router.navigate([pathAfterLogin]);
        } else if (wid) {
            localStorage.removeItem(LOCAL_STORAGE_KEY_ENUM.SOCIALSCAN_WID);
            this.router.navigate([`/meme-dashboard/watchlist-marketplace/${wid}`]);
        } else {
            this.router.navigate(['/my-profile']);
        }
    }
}
