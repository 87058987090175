import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MyProfileService } from '@src/app/modules/my-profile/my-profile.service';
import { CommonService } from '@src/app/services/common.service';
import { SocialmediaAuthService } from '@src/app/services/socialmedia-auth.service';
import { WalletStatusService } from '@src/app/services/wallet-status.service';
import { environment } from '@src/environments/environment';
import { isEmpty } from 'lodash';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { Subject } from 'rxjs';
import { filter, finalize, take, takeUntil } from 'rxjs/operators';
import { MemeDashboardStatusService } from '../../../meme-dashboard-status.service';
import { MemeDashboardService } from '../../../meme-dashboard.service';
import { AddWatchResult } from '../../../meme-dashboard.type';

@Component({
    selector: 'app-add-watchlist-modal',
    templateUrl: './add-watchlist-modal.component.html',
    styleUrls: ['./add-watchlist-modal.component.less'],
})
export class AddWatchlistModalComponent implements OnDestroy, OnInit {
    @ViewChild('successAlert') successAlert!: TemplateRef<void>;
    @Input() onlyUpdateAgentInfo = false;

    submitting = false;

    watchListText: string;

    socialMediaSub$: any;
    connectedTwitterId: string;

    addedInfo: {
        addedCount: number;
        duplicatedCount: number;
        duplicatedAddressLineNumbers: number[];
        notSupportedCount: number;
        notSupportedAddressLineNumbers: number[];
        solanaCount: number;
        solanaAddressLineNumbers: number[];
    } = {
        addedCount: 0,
        duplicatedCount: 0,
        duplicatedAddressLineNumbers: [],
        notSupportedCount: 0,
        notSupportedAddressLineNumbers: [],
        solanaCount: 0,
        solanaAddressLineNumbers: [],
    };

    invalidMessage: string;

    showError = false;

    steps = {
        current: 1,
        step1Enabled: true,
        step2Enabled: true,
        step3Enabled: true,
        step4Enabled: true,
    };

    agentInfo = {
        name: '',
        logo: '',
        introduction: '',
        allowSubscription: true,
    };

    // Logo
    uploading = false;
    fileList: NzUploadFile[] = [];

    savingAgentInfo = false;

    isFirstEditAgentInfo = false;

    beforeUpload = (file: NzUploadFile): boolean => {
        this.fileList = [file];
        const reader = new FileReader();
        reader.readAsDataURL(file as any);
        reader.onload = () => (this.agentInfo.logo = reader.result as string);

        this.handleUpload();
        return false;
    };

    private destroy$ = new Subject<void>();

    isStepCompleted(step: number): boolean {
        return step < this.steps.current;
    }

    isStepCurrent(step: number): boolean {
        return step === this.steps.current;
    }

    get watchLimitCount() {
        return this.memeDashboardStatusService.WatchLimitCount;
    }

    get agentInfoNextBtnDisabled() {
        return !this.agentInfo.name || !this.agentInfo.logo || this.uploading;
    }

    get moreThanOneSteps() {
        return (this.steps.step1Enabled ? 1 : 0) + (this.steps.step2Enabled ? 1 : 0) + (this.steps.step3Enabled ? 1 : 0) > 1;
    }

    get hasWatchlistWallets() {
        return this.memeDashboardStatusService.myWatchlistWallets$.getValue()?.length > 0;
    }

    constructor(
        private commonService: CommonService,
        private memeDashboardStatusService: MemeDashboardStatusService,
        private memeDashboardService: MemeDashboardService,
        public socialMediaAuth: SocialmediaAuthService,
        public walletStatusService: WalletStatusService,
        public drawerRef: NzDrawerRef,
        private myProfileService: MyProfileService
    ) {}

    ngOnInit() {
        // 监听MyWatchlist Agent 信息变化
        this.memeDashboardStatusService.myWatchlistAgentInfo$
            .pipe(
                filter(item => !!item),
                takeUntil(this.destroy$)
            )
            .subscribe(data => {
                this.agentInfo = {
                    name: data.name,
                    logo: data.logo_url,
                    introduction: data.introduction,
                    allowSubscription: !data.name ? true : data.allow_subscription,
                };

                this.steps = {
                    ...this.steps,
                    // 如果只是update agent info，则step2Enabled为true。否则就根据name是否为空来决定step2Enabled
                    step2Enabled: this.onlyUpdateAgentInfo || this.isFirstEditAgentInfo ? true : !data.name,
                };
            });

        if (this.onlyUpdateAgentInfo) {
            this.steps.step1Enabled = false;
            this.steps.step3Enabled = false;
            this.steps.step4Enabled = false;
            this.steps.current = 2;
            return;
        }

        if (!isEmpty(this.memeDashboardStatusService.memeAgentUserSettings?.my_watchlist_settings)) {
            this.steps.step3Enabled = false;
        }

        if (this.memeDashboardStatusService.memeAgentInitialized) {
            this.steps.step4Enabled = false;
        }

        this.steps = { ...this.steps };
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    getProgressLineWidth(): number {
        // Calculate total enabled steps
        const totalSteps =
            (this.steps.step1Enabled ? 1 : 0) + (this.steps.step2Enabled ? 1 : 0) + (this.steps.step3Enabled ? 1 : 0) + (this.steps.step4Enabled ? 1 : 0);

        return ((totalSteps - 1) / totalSteps) * 100;
    }

    confirmAdd() {
        // Reset
        this.addedInfo = {
            addedCount: 0,
            duplicatedCount: 0,
            duplicatedAddressLineNumbers: [],
            notSupportedCount: 0,
            notSupportedAddressLineNumbers: [],
            solanaCount: 0,
            solanaAddressLineNumbers: [],
        };

        this.submitting = true;

        const watchList = this.parseWatchList(this.watchListText);

        this.memeDashboardService
            .changeAddressesFromWatchlist({ address_to_add: watchList })
            .then(({ data }) => {
                const newAddressesCount = data.filter(item => item.is_added).length;
                if (newAddressesCount) {
                    this.handleSuccessAddedWallet(data);
                    this.handleSuccessAddedSteps();
                } else {
                    this.handleFailedAddedWallet(data, watchList);
                }
            })
            .catch(err => this.commonService.errorMessageByResponse(err))
            .finally(() => (this.submitting = false));
    }

    confirmEditAgentInfo() {
        this.savingAgentInfo = true;
        const params = {
            name: this.agentInfo.name,
            introduction: this.agentInfo.introduction,
            logo_url: this.agentInfo.logo,
            allow_subscription: this.agentInfo.allowSubscription,
        };
        this.memeDashboardService
            .createOrUpdateMyWatchListAgentInfo(params)
            .pipe(finalize(() => (this.savingAgentInfo = false)))
            .subscribe({
                next: res => {
                    if (this.steps.step3Enabled) {
                        this.steps.current = 3;
                        this.isFirstEditAgentInfo = true;
                    } else {
                        this.drawerRef.close({ agent_updated: res });
                    }

                    this.memeDashboardStatusService.myWatchlistAgentInfo$.next({
                        ...(this.memeDashboardStatusService.myWatchlistAgentInfo$.getValue() || ({} as any)),
                        ...res,
                    });
                },

                error: err => this.commonService.errorMessageByResponse(err),
            });
    }

    connectTwitter() {
        this.socialMediaAuth.connectTwitter();

        this.socialMediaSub$?.unsubscribe();
        this.socialMediaSub$ = this.socialMediaAuth.connectedTwitterInfo$
            .pipe(
                filter(item => !!item),
                take(1),
                takeUntil(this.destroy$)
            )
            .subscribe(() => this.socialMediaAuth.connectedTwitterInfo$.next(null));
    }

    handleUpload(): void {
        this.uploading = true;
        this.myProfileService
            .uploadFiles(this.fileList[0] as any)
            .then(res => (this.agentInfo.logo = res.file_url))
            .catch(err => {
                this.commonService.errorMessageByResponse(err);
                this.agentInfo.logo = null;
            })
            .finally(() => (this.uploading = false));
    }

    goToTgAgent() {
        window.open(`${environment.telegram.botLink}?start=bind_${this.walletStatusService.userProfile.id}`, '_blank');
    }

    calculateRealStepNum(step: number): number {
        let realStepNum = 0;

        // 检查每个步骤是否启用，并且是否小于等于目标步骤
        if (step >= 1 && this.steps.step1Enabled) realStepNum++;

        if (step >= 2 && this.steps.step2Enabled) realStepNum++;

        if (step >= 3 && this.steps.step3Enabled) realStepNum++;

        if (step >= 4 && this.steps.step4Enabled) realStepNum++;

        return realStepNum;
    }

    starSettingsSaved() {
        if (this.steps.step4Enabled) {
            this.steps.current = 4;
        } else {
            this.drawerRef.close();
        }
    }

    private parseWatchList(watchListText: string) {
        const addresses = watchListText.split('\n').map(line => line.trim());
        return addresses;
    }

    private handleSuccessAddedWallet(data: AddWatchResult[]) {
        data.forEach((item, index) => {
            if (item.is_added) {
                this.addedInfo.addedCount++;
            } else if (!item.is_added && (item as any).error_type === 'address_is_exist') {
                this.addedInfo.duplicatedCount++;
                this.addedInfo.duplicatedAddressLineNumbers.push(index + 1);
            } else if (!item.is_added && (item as any).error_type === 'not_supported_address') {
                this.addedInfo.notSupportedCount++;
                this.addedInfo.notSupportedAddressLineNumbers.push(index + 1);
            } else if (!item.is_added && (item as any).error_type === 'solana_address') {
                this.addedInfo.solanaCount++;
                this.addedInfo.solanaAddressLineNumbers.push(index + 1);
            }
        });

        this.commonService.success(this.successAlert, { nzDuration: 10000 });
        this.memeDashboardStatusService.setNewAddedWatchlistCount(this.addedInfo.addedCount);

        if (!this.walletStatusService.userProfile?.watchlist?.address_added) {
            this.walletStatusService.userProfile.watchlist = {
                ...(this.walletStatusService.userProfile.watchlist || ({} as any)),
                address_added: true,
            };
            this.commonService.success('Congrats! You successfully earned 100 Points as adding Watchlist reward.');
        }
    }

    private handleSuccessAddedSteps() {
        if (this.steps.step2Enabled) {
            this.steps.current = 2;
        } else if (this.steps.step3Enabled) {
            this.steps.current = 3;
        } else if (this.steps.step4Enabled) {
            this.steps.current = 4;
        } else {
            if (this.walletStatusService.userProfile.hunter_agent.agent_reminder) {
                this.memeDashboardStatusService.showHunterAgentSettingsDialog('watchlist_added');
            }

            this.drawerRef.close();
        }
    }

    private handleFailedAddedWallet(data: AddWatchResult[], watchList: string[]) {
        data.forEach((item, index) => {
            if (!item.is_added && (item as any).error_type === 'address_is_exist') {
                this.addedInfo.duplicatedCount++;
                this.addedInfo.duplicatedAddressLineNumbers.push(index + 1);
            } else if (!item.is_added && (item as any).error_type === 'not_supported_address') {
                this.addedInfo.notSupportedCount++;
                this.addedInfo.notSupportedAddressLineNumbers.push(index + 1);
            } else if (!item.is_added && (item as any).error_type === 'solana_address') {
                this.addedInfo.solanaCount++;
                this.addedInfo.solanaAddressLineNumbers.push(index + 1);
            }
        });

        this.invalidMessage = data.find(item => item.is_added === false && item.error_type === 'watchlist_full')
            ? `You have exceeded the limit by ${this.watchLimitCount - watchList.length}.`
            : null;

        this.showError = true;
    }
}
