import { Component, OnDestroy, OnInit, Optional } from '@angular/core';
import { CommonService } from '@services/common.service';
import { WalletStatusService } from '@services/wallet-status.service';
import { WalletEnum } from '@shared/types/wallet-chain.type';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { NzModalRef } from 'ng-zorro-antd/modal';

import { Router } from '@angular/router';
import { UseAppKitAccountReturn } from '@reown/appkit';
import { clusterApiUrl, Connection, PublicKey, SystemProgram, Transaction } from '@solana/web3.js';
import { AuthTokenService } from '@src/app/services/auth-token.service';
import { TelegramApiService } from '@src/app/services/telegram-api.service';
import { TelegramService } from '@src/app/services/telegram.service';
import { UserService } from '@src/app/services/user.service';
import { environment } from '@src/environments/environment';
import { NzMessageRef, NzMessageService } from 'ng-zorro-antd/message';
import { debounceTime, distinctUntilChanged, filter, Observable, Subscription } from 'rxjs';
import { LOCAL_STORAGE_KEY_ENUM } from '../../types/storage.type';

declare global {
    interface Window {
        signWithDevKey?: any;
    }
}

@Component({
    selector: 'app-connect-wallet-modal',
    templateUrl: './connect-wallet-modal.component.html',
    styleUrls: ['./connect-wallet-modal.component.less'],
})
export class ConnectWalletModalComponent implements OnInit, OnDestroy {
    WalletEnum = WalletEnum;

    connectingWallet: WalletEnum;

    wallets = this.walletStatusService.detectWalletsBasedOnDevice();

    private wcAccountChangeSub: Subscription;

    // 此处加入loading 的原因主要为了移动端。
    // 有些环境比如 TG MiniApp, 再关联钱包时，有时需要用户手动切换 APP，才可以继续。
    private loadingRefs: NzMessageRef[] = [];

    get loginTGLink() {
        return `${environment.telegram.socialScanBotLink}?start=login`;
    }

    constructor(
        @Optional() public modalRef: NzModalRef,
        @Optional() public drawerRef: NzDrawerRef,
        private commonService: CommonService,
        private router: Router,
        private walletStatusService: WalletStatusService,
        private tgAPIService: TelegramApiService,
        private messageService: NzMessageService,
        private authTokenService: AuthTokenService,
        public userService: UserService,
        public tgService: TelegramService
    ) {}

    ngOnInit() {
        this.handleWalletConnect();
    }

    ngOnDestroy() {
        this.wcAccountChangeSub?.unsubscribe();
        this.removeMessage();
    }

    async connect(type: WalletEnum) {
        try {
            switch (type) {
                case WalletEnum.WALLET_CONNECT:
                    await this.connectWalletConnect();
                    break;

                case WalletEnum.TRUST_WALLET:
                    await this.connectTrustWallet();
                    break;

                case WalletEnum.META_MASK:
                    await this.connectMetaMask();
                    break;
            }
        } catch (err: any) {
            if (err.code === -30002) {
                this.commonService.error(err.message || null);
            }
        }
    }

    private async connectWalletConnect() {
        this.removeMessage();
        this.connectingWallet = WalletEnum.WALLET_CONNECT;
        this.wcAccountChangeSub?.unsubscribe();

        try {
            this.walletStatusService.web3Modal.open();

            const accountChanges$ = new Observable<UseAppKitAccountReturn>(subscriber =>
                this.walletStatusService.web3Modal.subscribeAccount(account => subscriber.next(account))
            );

            this.wcAccountChangeSub = accountChanges$
                .pipe(
                    debounceTime(500),
                    distinctUntilChanged((prev, curr) => prev?.isConnected === curr?.isConnected),
                    filter(data => data?.isConnected)
                )
                .subscribe(async ({ address }) => {
                    this.removeMessage();
                    this.loadingRefs.push(this.commonService.loading('Please check your wallet to sign the message...', { nzDuration: 0 }));
                    const message = this.walletStatusService.getMessageToSign();
                    const sign: string = await (this.walletStatusService.web3Modal.getWalletProvider() as any).request({
                        method: 'personal_sign',
                        params: [message, address],
                    });
                    this.removeMessage();
                    this.handleTGLoginAndLoginStatus(WalletEnum.WALLET_CONNECT, { address, message, sign });
                });
        } catch (err: any) {
            this.commonService.error(err.message);
            this.connectingWallet = null;
            console.warn(err);
        }
    }

    private async connectMetaMask() {
        this.connectingWallet = WalletEnum.META_MASK;
        let mmProvider = window.ethereum as any;

        if (mmProvider?.providers?.length) {
            mmProvider = mmProvider.providers?.find((item: any) => item.isMetaMask);
        }

        if (!mmProvider) {
            if (this.commonService.isMobile) {
                window.location.href = `https://metamask.app.link/dapp/${window.location.protocol}//${window.location.host}/${window.location.pathname}`;
            } else {
                window.open('https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn', '_blank');
            }
            this.connectingWallet = null;
            return;
        }

        try {
            const accounts = await mmProvider.request({ method: 'eth_requestAccounts', params: [] });
            const message = this.walletStatusService.getMessageToSign();
            const sign = await mmProvider.request({ method: 'personal_sign', params: [message, accounts[0]] });
            this.handleTGLoginAndLoginStatus(WalletEnum.META_MASK, { address: accounts[0], message, sign });
        } catch (err: any) {
            this.commonService.error(err?.message);
            this.connectingWallet = null;
        }
    }

    private async connectTrustWallet() {
        const trustWalletProvider = this.walletStatusService.getTrustWalletFromWindow();
        this.connectingWallet = WalletEnum.TRUST_WALLET;

        if (!trustWalletProvider) {
            if (this.commonService.isMobile) {
                // eslint-disable-next-line max-len
                window.location.href = `https://link.trustwallet.com/open_url?url=${window.location.protocol}//${window.location.host}/${window.location.pathname}&coin_id=60`;
            } else {
                window.open('https://chrome.google.com/webstore/detail/trust-wallet/egjidjbpglichdcondbcbdnbeeppgdph', '_blank');
            }
            this.connectingWallet = null;
            return;
        }

        try {
            const accounts = await trustWalletProvider.request({ method: 'eth_requestAccounts' });
            const message = this.walletStatusService.getMessageToSign();
            const sign = await trustWalletProvider.request({
                method: 'personal_sign',
                params: [message, accounts[0]],
            });
            this.handleTGLoginAndLoginStatus(WalletEnum.TRUST_WALLET, { address: accounts[0], message, sign });
            this.connectingWallet = null;
        } catch (err: any) {
            this.commonService.error(err.message);
            this.connectingWallet = null;
        }
    }

    async connectPhantom(type: 'phantom' | 'okx') {
        if ('solana' in window) {
            const phantomProvider = (window as any).solana;
            if (phantomProvider.isPhantom) {
                try {
                    let provider = (window as any).solana;

                    if (type === 'okx') {
                        provider = (window as any).okxwallet.solana;
                    }

                    const resp = await provider.connect();
                    const pubKey = resp.publicKey.toString();
                    // if (this.modalConfig.combineWallet) {
                    //     this.walletStatusService.pendingCombinedWallet$.next({
                    //         walletType: NFTPlatform.PHANTOM,
                    //         walletAddr: pubKey,
                    //     });
                    // } else {
                    //     this.walletStatusService.setSessionData(NFTPlatform.PHANTOM, pubKey);
                    // }
                    const message = `Sign in to My Dapp with ${pubKey}`;
                    const encodedMessage = new TextEncoder().encode(message);
                    const signedMessage = await provider.signMessage(encodedMessage, 'utf8');

                    console.log('✅ Phantom 签名成功:', signedMessage);
                    console.log(pubKey);
                    console.log('Phantom');

                    this.modalRef.triggerOk();
                } catch (err: any) {
                    this.commonService.error(err.message);
                }
            }
        } else {
            if (this.commonService.isMobile) {
                const encodeUri = encodeURIComponent(location.href);
                window.location.href = `https://phantom.app/ul/browse/${encodeUri}?ref=${encodeUri}`;
            } else {
                window.open('https://phantom.app/', '_blank');
            }
        }
    }

    connectOKXSolana() {
        if ('solana' in window) {
            const provider = (window as any).solana;
            provider.connect();
        }
    }

    private close() {
        this.modalRef?.triggerCancel();
        this.drawerRef?.close();
    }

    private handleTGLoginAndLoginStatus(wallet: WalletEnum, data: { address: string; message: string; sign: string }) {
        // 如果是在 TG 环境下，说明已经登陆了TG，此时关联钱包意味着绑定当前钱包到 TG 账号
        if (this.tgService.isInTelegramWebApp || this.tgService.isTGLogin) {
            this.tgAPIService
                .connectWalletWithTG({
                    wallet_address: data.address,
                    message: data.message,
                    signature: data.sign,
                })
                .then(() => {
                    this.walletStatusService.updateCurrentWallet({
                        wallet,
                        walletAddress: data.address,
                    });
                    this.commonService.success('Connection successful!');
                    setTimeout(() => {
                        window.location.reload();
                    }, 500);
                })
                .catch(err => {
                    if (err.status === 400) {
                        this.commonService.error('Connection failed. Please select a new Wallet that has never been connected to SocialScan.');
                    } else {
                        this.commonService.errorMessageByResponse(err);
                    }
                })
                .finally(() => this.close());
        } else {
            this.walletStatusService
                .refreshUserToken({
                    wallet_address: data.address,
                    message: data.message,
                    signature: data.sign,
                })
                .then(({ auth }) => {
                    this.authTokenService.setAuthToken(auth);

                    this.walletStatusService.updateCurrentWallet({
                        wallet,
                        walletAddress: data.address,
                    });

                    if (this.authTokenService.signUpCompleted) {
                        const pathAfterLogin = localStorage.getItem(LOCAL_STORAGE_KEY_ENUM.PATH_AFTER_LOGIN);
                        if (pathAfterLogin) {
                            localStorage.removeItem(LOCAL_STORAGE_KEY_ENUM.PATH_AFTER_LOGIN);
                            this.router.navigate([pathAfterLogin]);
                        } else {
                            /**
                             * 这一块已经改成使用redirectAfterLogin 的形式
                             * 但是保留代码，保证历史链接仍然有效
                             * 保留 3 个月左右，至 2025-06-12
                             */
                            const wid = localStorage.getItem(LOCAL_STORAGE_KEY_ENUM.SOCIALSCAN_WID);
                            if (wid) {
                                this.router.navigate(['/meme-dashboard/watchlist-marketplace', wid]);
                                localStorage.removeItem(LOCAL_STORAGE_KEY_ENUM.SOCIALSCAN_WID);
                            } else {
                                if (this.router.url == '' || this.router.url === '/') {
                                    this.router.navigate(['/my-profile']);
                                }
                            }
                        }
                    } else {
                        this.commonService.showEmailInvitationModal();
                    }
                })
                .catch(err => {
                    this.commonService.errorMessageByResponse(err as any);

                    setTimeout(() => {
                        // TODO: 使用登出的形式
                        this.userService.logout();
                    }, 1000);
                })
                .finally(() => this.close());
        }
    }

    private removeMessage() {
        this.loadingRefs.filter(ref => !!ref).forEach(ref => this.messageService.remove(ref.messageId));
    }

    private handleWalletConnect() {
        // 有时 walletconnect 没有退出登录，所以需要手动退出
        if (this.walletStatusService.isWalletConnected && this.walletStatusService.wallet === WalletEnum.WALLET_CONNECT) {
            this.walletStatusService.web3Modal.disconnect();
        }
    }

    async sendSOL() {
        // TODO: 当前关联的那个钱包，就从哪个钱包取 provider
        const provider = (window as any).solana;

        const resp = await provider.connect();
        const senderPublicKey = resp.publicKey.toString();
        const toAddress = 'FEdWTzN3Bd1nsEQC8uu1sxZkCS9wCPk79RPvta2L7Uzj';
        const sender = new PublicKey(senderPublicKey);
        const recipient = new PublicKey(toAddress);
        const amount = 1 * 1e9; // SOL 转换为 lamports (1 SOL = 10^9 lamports)
        const connection = new Connection(clusterApiUrl('devnet'), 'confirmed');

        // 创建转账交易
        const transaction = new Transaction().add(
            SystemProgram.transfer({
                fromPubkey: sender,
                toPubkey: recipient,
                lamports: amount,
            })
        );

        transaction.feePayer = sender;
        transaction.recentBlockhash = (await connection.getLatestBlockhash()).blockhash;

        // 让用户签名交易
        const signedTransaction = await provider.signTransaction(transaction);

        // 发送交易到 Solana 网络
        const txid = await connection.sendRawTransaction(signedTransaction.serialize());
        console.log('txid', txid);
    }
}
