import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthTokenService } from '@src/app/services/auth-token.service';
import { CommonService } from '@src/app/services/common.service';
import { TelegramService } from '@src/app/services/telegram.service';
import { UserService } from '@src/app/services/user.service';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard {
    constructor(
        private router: Router,
        private tgService: TelegramService,
        private commonService: CommonService,
        private authTokenService: AuthTokenService,
        private userService: UserService
    ) {}

    async canActivate() {
        if (this.tgService.isInTelegramWebApp || this.tgService.isTGLogin) {
            return true;
        }

        if (!this.userService.isAuthenticated) {
            this.commonService.showLogInModal();
            return false;
        }

        // If token expired, then disconnect wallet. And redirect to home page.
        if (this.authTokenService.isTokenExpired()) {
            this.userService.logout();
            this.router.navigate(['/'], { queryParamsHandling: 'merge' });
            return false;
        }

        return true;
    }
}
