import { Injectable } from '@angular/core';
import { jwtDecode } from 'jwt-decode';
import { BehaviorSubject } from 'rxjs';
import { LOCAL_STORAGE_KEY_ENUM } from '../shared/types/storage.type';
import { CommonService } from './common.service';

/**
 * 对于关联钱包的情况，略有不同。
 * 当用户关联钱包，会出现先关联钱包，再设置 token 的情况。
 */
@Injectable({
    providedIn: 'root',
})
export class AuthTokenService {
    tokenStr$ = new BehaviorSubject<string>(null);

    tokenExp: number; // milliseconds

    signUpCompleted = false;

    get tokenStr() {
        return this.tokenStr$.getValue();
    }

    constructor(private commonService: CommonService) {
        if (this.commonService.checkIfInBrowserPlatform()) {
            this.setupInitialState();
        }
    }

    setAuthToken(token: string) {
        localStorage.setItem(LOCAL_STORAGE_KEY_ENUM.TOKEN, token);

        const decodedInfo = jwtDecode(token) as any;

        this.tokenExp = decodedInfo?.exp * 1000;

        this.signUpCompleted = decodedInfo?.registration_complete;

        this.tokenStr$.next(token);
    }

    clearAuthToken() {
        this.tokenStr$.next(null);
        localStorage.removeItem(LOCAL_STORAGE_KEY_ENUM.TOKEN);
    }

    setupInitialState() {
        const tokenStr = localStorage.getItem(LOCAL_STORAGE_KEY_ENUM.TOKEN);

        if (tokenStr) {
            try {
                this.setAuthToken(tokenStr);

                if (!this.signUpCompleted) {
                    setTimeout(() => {
                        this.commonService.showEmailInvitationModal();
                    }, 100);
                }
            } catch (er) {
                this.clearAuthToken();
                window.location.reload();
            }
        }
    }

    isTokenExpired() {
        return this.tokenExp < Date.now();
    }
}
