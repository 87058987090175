import { Component, inject } from '@angular/core';
import { CommonService } from '@src/app/services/common.service';
import { WalletStatusService } from '@src/app/services/wallet-status.service';
import { environment } from '@src/environments/environment';
import { NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';
import { MemeDashboardService } from '../../meme-dashboard.service';

@Component({
    selector: 'app-watchlist-agent-setup-guide',
    templateUrl: './watchlist-agent-setup-guide.component.html',
    styleUrls: ['./watchlist-agent-setup-guide.component.less'],
})
export class WatchlistAgentSetupGuideComponent {
    readonly nzModalData: {
        type: 'watchlist_added' | 'init_tg_agent';
    } = inject(NZ_MODAL_DATA);

    private checkInitTimer: any;

    constructor(
        private walletStatusService: WalletStatusService,
        private memeDashboardService: MemeDashboardService,
        private modalRef: NzModalRef,
        private commonService: CommonService
    ) {}

    goToTgAgent() {
        window.open(`${environment.telegram.botLink}?start=bind_${this.walletStatusService.userProfile.id}`, '_blank');
        this.startCheckingInit();
    }

    // 5s轮询获取settings, 如果 initialized 变成了true，则关闭modal
    private startCheckingInit() {
        this.checkInitTimer = setInterval(() => {
            this.memeDashboardService.getMemeAgentUserSettings().subscribe(res => {
                if (res.agent_initialized) {
                    clearInterval(this.checkInitTimer);
                    this.modalRef.close();
                    this.walletStatusService.userProfile.hunter_agent.agent_initialized = true;
                    this.commonService.success('SocialScan Hunter Agent initialized successfully');
                }
            });
        }, 5000);
    }
}
