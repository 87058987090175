import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
    Sentry.init({
        dsn: 'https://6c6283cccbdcbd3deb568fc0d6f95f88@o1376794.ingest.us.sentry.io/4508969227452416',
        environment: 'production',
        ignoreErrors: [
            // 忽略所有 sourcemap 相关的警告
            /no sourcemap found, debug id/,
            /could not determine a source map reference/,
            /(Could not auto-detect referenced sourcemap for)/,
        ],
    });
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch(err => console.error(err));
