import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonService } from '@src/app/services/common.service';
import { WalletStatusService } from '@src/app/services/wallet-status.service';
import { formatNumberToKMB } from '@src/app/shared/utils/number.util';
import { environment } from '@src/environments/environment';
import { isArray } from 'lodash';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { MemeDashboardService } from '../../meme-dashboard.service';
import { MemeAgentUserSettings, TrackOption } from '../../meme-dashboard.type';

type OptionType = 'quickSelect' | 'custom';
type FieldType = 'marketCap' | 'txVolume24h' | 'liquidity' | 'poolAge' | 'top10Holders' | 'smartBuy' | 'smartWatchlistSimpleBuy';

@Component({
    selector: 'app-hunter-agent-settings',
    templateUrl: './hunter-agent-settings.component.html',
    styleUrls: ['./hunter-agent-settings.component.less'],
})
export class HunterAgentSettingsComponent implements OnInit, OnDestroy {
    marketCap: [number, number];
    marketCapType: OptionType = 'quickSelect';
    txVolume24h: [number, number];
    txVolume24hType: OptionType = 'quickSelect';
    liquidity: [number, number];
    liquidityType: OptionType = 'quickSelect';
    poolAge: [number, number];
    poolAgeType: OptionType = 'quickSelect';
    disabledTrackOptions: TrackOption[] = [];
    starLevel = 0;
    hoverStarLevel = 0;
    top10Holders = 0;
    smartBuyRange: [number, number];
    smartWatchlistSimpleBuyRange: [number, number];
    Infinity = Infinity;

    formatter = (value: number): string => {
        return value ? formatNumberToKMB(value) : '';
    };

    parser = (value: string): string => {
        return value.replace(/[^\d.]/g, '');
    };

    disableReminder = false;

    marketCapOptions: { label: string; value: [number, number] }[] = [
        { label: '<100K', value: [null, 100000] },
        { label: '100K-50M', value: [100000, 50000000] },
        { label: '>50M', value: [50000000, null] },
    ];

    txVolume24hOptions: { label: string; value: [number, number] }[] = [
        { label: '>50K', value: [50000, null] },
        { label: '>100K', value: [100000, null] },
        { label: '>500K', value: [500000, null] },
    ];

    liquidityOptions: { label: string; value: [number, number] }[] = [
        { label: '<10K', value: [null, 10000] },
        { label: '10K-5M', value: [10000, 5000000] },
        { label: '>5M', value: [5000000, null] },
    ];

    poolAgeOptions: { label: string; value: [number, number] }[] = [
        { label: '<1h', value: [null, 1] },
        { label: '<6h', value: [null, 6] },
        { label: '<12h', value: [null, 12] },
    ];

    submitting = false;

    loading = false;

    private checkInitTimer: any;

    constructor(
        private memeDashboardService: MemeDashboardService,
        private drawerRef: NzDrawerRef,
        private commonService: CommonService,
        private walletStatusService: WalletStatusService
    ) {}

    ngOnInit() {
        this.loading = true;
        this.memeDashboardService.getMemeAgentUserSettings().subscribe({
            next: res => this.setupInitData(res),
            error: err => this.commonService.errorMessageByResponse(err),
            complete: () => (this.loading = false),
        });
    }

    ngOnDestroy() {
        if (this.checkInitTimer) {
            clearInterval(this.checkInitTimer);
        }
    }

    saveSettings() {
        this.submitting = true;

        this.memeDashboardService
            .setMemeAgentUserSettings({
                agent_settings: {
                    market_cap: this.marketCap.map(item => (item ? item : null)) as [number, number],
                    tx_volume_24h: this.txVolume24h.map(item => (item ? item : null)) as [number, number],
                    liquidity: this.liquidity.map(item => (item ? item : null)) as [number, number],
                    pool_age: this.poolAge.map(item => (item ? item * 3600 : null)) as [number, number],
                    disabled_track_options: this.disabledTrackOptions,
                    top_10_holders_percentage: this.top10Holders || null,
                    smart_buy_range: this.smartBuyRange.map(item => (item ? item : null)) as [number, number],
                    smart_watchlist_simple_buy_range: this.smartWatchlistSimpleBuyRange.map(item => (item ? item : null)) as [number, number],
                    star_level: this.starLevel || null,
                },
                agent_reminder: !this.disableReminder,
            })
            .subscribe({
                next: () => {
                    this.drawerRef.close();
                    this.commonService.success('Settings saved successfully');
                    this.walletStatusService.userProfile.hunter_agent.agent_reminder = !this.disableReminder;
                },
                error: err => this.commonService.errorMessageByResponse(err),
            });
    }

    goToTgAgent() {
        window.open(`${environment.telegram.botLink}`, '_blank');
    }

    disabledTrackOptionsChange(status: boolean, option: TrackOption) {
        if (!status) {
            this.disabledTrackOptions.push(option);
        } else {
            this.disabledTrackOptions = this.disabledTrackOptions.filter(item => item !== option);
        }
    }

    toggleInputType(type: FieldType) {
        if (type === 'marketCap') {
            this.marketCapType = this.marketCapType === 'quickSelect' ? 'custom' : 'quickSelect';
            this.marketCap = [null, null];
        }

        if (type === 'txVolume24h') {
            this.txVolume24hType = this.txVolume24hType === 'quickSelect' ? 'custom' : 'quickSelect';
            this.txVolume24h = [null, null];
        }

        if (type === 'liquidity') {
            this.liquidityType = this.liquidityType === 'quickSelect' ? 'custom' : 'quickSelect';
            this.liquidity = [null, null];
        }

        if (type === 'poolAge') {
            this.poolAgeType = this.poolAgeType === 'quickSelect' ? 'custom' : 'quickSelect';
            this.poolAge = [null, null];
        }
    }

    isEmptyFiled(data: [number, number]) {
        return data[0] === null && data[1] === null;
    }

    revertFieldData(type: FieldType) {
        switch (type) {
            case 'marketCap':
                this.marketCap = [null, null];
                break;
            case 'txVolume24h':
                this.txVolume24h = [null, null];
                break;
            case 'liquidity':
                this.liquidity = [null, null];
                break;
            case 'poolAge':
                this.poolAge = [null, null];
                break;
            case 'top10Holders':
                this.top10Holders = null;
                break;
            case 'smartBuy':
                this.smartBuyRange = [null, null];
                break;
            case 'smartWatchlistSimpleBuy':
                this.smartWatchlistSimpleBuyRange = [null, null];
                break;
        }
    }

    reset() {
        ['marketCap', 'txVolume24h', 'liquidity', 'poolAge', 'top10Holders', 'smartBuy', 'smartWatchlistSimpleBuy'].forEach(type => {
            this.revertFieldData(type as FieldType);

            switch (type) {
                case 'marketCap':
                    this.marketCapType = 'quickSelect';
                    break;
                case 'txVolume24h':
                    this.txVolume24hType = 'quickSelect';
                    break;
                case 'liquidity':
                    this.liquidityType = 'quickSelect';
                    break;
                case 'poolAge':
                    this.poolAgeType = 'quickSelect';
                    break;
            }
        });

        this.starLevel = 0;
    }

    private setupInitData(data: MemeAgentUserSettings) {
        // 处理 marketCap
        const marketCapFromOptions = this.marketCapOptions.find(
            item => item.value[0] === data.agent_settings.market_cap?.[0] && item.value[1] === data.agent_settings.market_cap?.[1]
        )?.value;

        if (marketCapFromOptions) {
            this.marketCap = marketCapFromOptions;
            this.marketCapType = 'quickSelect';
        } else if (data.agent_settings.market_cap && !this.isEmptyFiled(data.agent_settings.market_cap as [number, number])) {
            this.marketCap = data.agent_settings.market_cap as [number, number];
            this.marketCapType = 'custom';
        } else {
            this.marketCap = [null, null];
            this.marketCapType = 'quickSelect';
        }

        // 处理 txVolume24h
        const txVolumeFromOptions = this.txVolume24hOptions.find(
            item => item.value[0] === data.agent_settings.tx_volume_24h?.[0] && item.value[1] === data.agent_settings.tx_volume_24h?.[1]
        )?.value;

        if (txVolumeFromOptions) {
            this.txVolume24h = txVolumeFromOptions;
            this.txVolume24hType = 'quickSelect';
        } else if (isArray(data.agent_settings.tx_volume_24h) && !this.isEmptyFiled(data.agent_settings.tx_volume_24h as [number, number])) {
            this.txVolume24h = data.agent_settings.tx_volume_24h as [number, number];
            this.txVolume24hType = 'custom';
        } else {
            this.txVolume24h = [null, null];
            this.txVolume24hType = 'quickSelect';
        }

        // 处理 liquidity
        const liquidityFromOptions = this.liquidityOptions.find(
            item => item.value[0] === data.agent_settings.liquidity?.[0] && item.value[1] === data.agent_settings.liquidity?.[1]
        )?.value;

        if (liquidityFromOptions) {
            this.liquidity = liquidityFromOptions;
            this.liquidityType = 'quickSelect';
        } else if (data.agent_settings.liquidity && !this.isEmptyFiled(data.agent_settings.liquidity as [number, number])) {
            this.liquidity = data.agent_settings.liquidity as [number, number];
            this.liquidityType = 'custom';
        } else {
            this.liquidity = [null, null];
            this.liquidityType = 'quickSelect';
        }

        // 处理 poolAge
        const poolAgeFromOptions = this.poolAgeOptions.find(
            item => item.value[0] === data.agent_settings.pool_age?.[0] / 3600 && item.value[1] === data.agent_settings.pool_age?.[1] / 3600
        )?.value;

        if (poolAgeFromOptions) {
            this.poolAge = poolAgeFromOptions;
            this.poolAgeType = 'quickSelect';
        } else if (data.agent_settings.pool_age && !this.isEmptyFiled(data.agent_settings.pool_age as [number, number])) {
            this.poolAge = data.agent_settings.pool_age.map(item => item / 3600) as [number, number];
            this.poolAgeType = 'custom';
        } else {
            this.poolAge = [null, null];
            this.poolAgeType = 'quickSelect';
        }

        // 设置其他字段
        this.top10Holders = data.agent_settings.top_10_holders_percentage || 0;
        this.smartBuyRange = data.agent_settings.smart_buy_range || [null, null];
        this.smartWatchlistSimpleBuyRange = data.agent_settings.smart_watchlist_simple_buy_range || [null, null];
        this.disableReminder = !data.agent_reminder;
        this.disabledTrackOptions = data.agent_settings.disabled_track_options || [];
        this.starLevel = data.agent_settings.star_level || 0;
    }
}
