<div *ngIf="!loading" class="space-y-8 pr-1 h-full overflow-y-auto">
    <div class="text-sm font-medium">TG Hunter Agent Setting</div>

    <div class="space-y-4">
        <div class="text-sm font-medium">Track Setting</div>
        <div class="p-4 rounded-lg border border-line space-y-4 flex-col flex justify-start items-start">
            <div>
                <label
                    nz-checkbox
                    [ngModel]="!disabledTrackOptions.includes('my_watchlist')"
                    (ngModelChange)="disabledTrackOptionsChange($event, 'my_watchlist')"
                >
                    My Watchlist
                </label>
            </div>
            <div>
                <label nz-checkbox [ngModel]="!disabledTrackOptions.includes('smart_buys')" (ngModelChange)="disabledTrackOptionsChange($event, 'smart_buys')">
                    Smart Buys (By SocialScan)
                </label>
            </div>
            <div>
                <label
                    nz-checkbox
                    [ngModel]="!disabledTrackOptions.includes('subscribed_watchlist')"
                    (ngModelChange)="disabledTrackOptionsChange($event, 'subscribed_watchlist')"
                >
                    My Subscribed Watchlist
                </label>
            </div>
        </div>
    </div>

    <div class="space-y-4">
        <div class="w-full flex-between-center gap-x-2">
            <div class="text-sm font-medium">Set Filter Criteria for MEME Alert</div>
            <button nz-button nzType="text" nzSize="small" (click)="reset()">
                <div class="text-xs text-sub-text flex-start-center gap-x-1">
                    <span>Reset</span>
                    <span nz-icon nzType="reload" nzTheme="outline"></span>
                </div>
            </button>
        </div>

        <div class="p-4 rounded-lg border border-line flex flex-col gap-y-4 w-full justify-start items-stretch">
            <div class="filter-box-item">
                <div class="font-medium text-black">24h Volume($)</div>
                <div class="flex-start-center gap-x-3 mt-3">
                    <ng-container *ngIf="txVolume24hType === 'quickSelect'">
                        <button
                            *ngFor="let item of txVolume24hOptions"
                            nz-button
                            nzSize="small"
                            (click)="txVolume24h = item.value"
                            class="option-item"
                            [ngClass]="{
                            'not-active': txVolume24h !== item.value
                        }"
                        >
                            {{item.label}}
                        </button>
                    </ng-container>

                    <ng-container *ngIf="txVolume24hType === 'custom'">
                        <div class="input-item-box" [class.active]="!!txVolume24h[0]">
                            <nz-input-number
                                [nzFormatter]="formatter"
                                [(ngModel)]="txVolume24h[0]"
                                [nzParser]="parser"
                                class="!w-[108px] input-item"
                                nzPlaceHolder="Min"
                                [nzMin]="0"
                            ></nz-input-number>
                            <span class="suffix-icon">$</span>
                        </div>
                        <div class="input-item-box" [class.active]="!!txVolume24h[1]">
                            <nz-input-number
                                [nzFormatter]="formatter"
                                [(ngModel)]="txVolume24h[1]"
                                [nzParser]="parser"
                                class="!w-[108px] input-item"
                                nzPlaceHolder="Max"
                                [nzMin]="txVolume24h[0]"
                            ></nz-input-number>
                            <span class="suffix-icon">$</span>
                        </div>
                    </ng-container>

                    <span
                        nz-icon
                        [nzType]="txVolume24hType === 'quickSelect' ? 'antd:v2-edit' : 'antd:v2-reverse'"
                        class="icon-btn select-none"
                        nzTheme="outline"
                        (click)="toggleInputType('txVolume24h')"
                    ></span>

                    <span
                        nz-icon
                        nzType="antd:v2-brush"
                        class="icon-btn"
                        nzTheme="outline"
                        [ngClass]="{
                            'disabled': isEmptyFiled(txVolume24h)
                        }"
                        (click)="revertFieldData('txVolume24h')"
                    ></span>
                </div>
            </div>

            <div class="filter-box-item">
                <div class="font-medium text-black">Market Cap ($)</div>
                <div class="flex-start-center gap-x-3 mt-3">
                    <ng-container *ngIf="marketCapType === 'quickSelect'">
                        <button
                            *ngFor="let item of marketCapOptions"
                            nz-button
                            nzSize="small"
                            (click)="marketCap = item.value"
                            class="option-item"
                            [ngClass]="{
                                'not-active': marketCap !== item.value
                            }"
                        >
                            {{item.label}}
                        </button>
                    </ng-container>

                    <ng-container *ngIf="marketCapType === 'custom'">
                        <div class="input-item-box" [class.active]="!!marketCap[0]">
                            <nz-input-number
                                [nzFormatter]="formatter"
                                [(ngModel)]="marketCap[0]"
                                [nzParser]="parser"
                                class="!w-[108px] input-item"
                                nzPlaceHolder="Min"
                                [nzMin]="0"
                            ></nz-input-number>
                            <span class="suffix-icon">$</span>
                        </div>
                        <div class="input-item-box" [class.active]="!!marketCap[1]">
                            <nz-input-number
                                [nzFormatter]="formatter"
                                [(ngModel)]="marketCap[1]"
                                [nzParser]="parser"
                                class="!w-[108px] input-item"
                                nzPlaceHolder="Max"
                                [nzMin]="marketCap[0]"
                            ></nz-input-number>
                            <span class="suffix-icon">$</span>
                        </div>
                    </ng-container>

                    <span
                        nz-icon
                        [nzType]="marketCapType === 'quickSelect' ? 'antd:v2-edit' : 'antd:v2-reverse'"
                        class="icon-btn select-none"
                        nzTheme="outline"
                        (click)="toggleInputType('marketCap')"
                    ></span>

                    <span
                        nz-icon
                        nzType="antd:v2-brush"
                        class="icon-btn"
                        nzTheme="outline"
                        [ngClass]="{
                            'disabled': isEmptyFiled(marketCap)
                        }"
                        (click)="revertFieldData('marketCap')"
                    ></span>
                </div>
            </div>

            <div class="filter-box-item">
                <div class="font-medium text-black">Liquidity ($)</div>
                <div class="flex-start-center gap-x-3 mt-3">
                    <ng-container *ngIf="liquidityType === 'quickSelect'">
                        <button
                            *ngFor="let item of liquidityOptions"
                            nz-button
                            nzSize="small"
                            (click)="liquidity = item.value"
                            class="option-item"
                            [ngClass]="{
                            'not-active': liquidity !== item.value
                        }"
                        >
                            {{item.label}}
                        </button>
                    </ng-container>

                    <ng-container *ngIf="liquidityType === 'custom'">
                        <div class="input-item-box" [class.active]="!!liquidity[0]">
                            <nz-input-number
                                [nzFormatter]="formatter"
                                [(ngModel)]="liquidity[0]"
                                [nzParser]="parser"
                                class="!w-[108px] input-item"
                                nzPlaceHolder="Min"
                                [nzMin]="0"
                            ></nz-input-number>
                            <span class="suffix-icon">$</span>
                        </div>
                        <div class="input-item-box" [class.active]="!!liquidity[1]">
                            <nz-input-number
                                [nzFormatter]="formatter"
                                [(ngModel)]="liquidity[1]"
                                [nzParser]="parser"
                                class="!w-[108px] input-item"
                                nzPlaceHolder="Max"
                                [nzMin]="liquidity[0]"
                            ></nz-input-number>
                            <span class="suffix-icon">$</span>
                        </div>
                    </ng-container>

                    <span
                        nz-icon
                        [nzType]="liquidityType === 'quickSelect' ? 'antd:v2-edit' : 'antd:v2-reverse'"
                        class="icon-btn select-none"
                        nzTheme="outline"
                        (click)="toggleInputType('liquidity')"
                    ></span>

                    <span
                        nz-icon
                        nzType="antd:v2-brush"
                        class="icon-btn"
                        nzTheme="outline"
                        [ngClass]="{
                            'disabled': isEmptyFiled(liquidity)
                        }"
                        (click)="revertFieldData('liquidity')"
                    ></span>
                </div>
            </div>

            <div class="filter-box-item">
                <div class="font-medium text-black">Token Age</div>
                <div class="flex-start-center gap-x-3 mt-3">
                    <ng-container *ngIf="poolAgeType === 'quickSelect'">
                        <button
                            *ngFor="let item of poolAgeOptions"
                            nz-button
                            nzSize="small"
                            (click)="poolAge = item.value"
                            class="option-item"
                            [ngClass]="{
                            'not-active': poolAge !== item.value
                        }"
                        >
                            {{item.label}}
                        </button>
                    </ng-container>

                    <ng-container *ngIf="poolAgeType === 'custom'">
                        <div class="input-item-box" [class.active]="!!poolAge[0]">
                            <nz-input-number
                                [nzFormatter]="formatter"
                                [(ngModel)]="poolAge[0]"
                                [nzParser]="parser"
                                class="!w-[108px] input-item"
                                nzPlaceHolder="Min"
                                [nzMin]="0"
                            ></nz-input-number>
                            <span class="suffix-icon">h</span>
                        </div>
                        <div class="input-item-box" [class.active]="!!poolAge[1]">
                            <nz-input-number
                                [nzFormatter]="formatter"
                                [(ngModel)]="poolAge[1]"
                                [nzParser]="parser"
                                class="!w-[108px] input-item"
                                nzPlaceHolder="Max"
                                [nzMin]="poolAge[0]"
                            ></nz-input-number>
                            <span class="suffix-icon">h</span>
                        </div>
                    </ng-container>

                    <span
                        nz-icon
                        [nzType]="poolAgeType === 'quickSelect' ? 'antd:v2-edit' : 'antd:v2-reverse'"
                        class="icon-btn select-none"
                        nzTheme="outline"
                        (click)="toggleInputType('poolAge')"
                    ></span>

                    <span
                        nz-icon
                        nzType="antd:v2-brush"
                        class="icon-btn"
                        nzTheme="outline"
                        [ngClass]="{
                            'disabled': isEmptyFiled(poolAge)
                        }"
                        (click)="revertFieldData('poolAge')"
                    ></span>
                </div>
            </div>

            <div class="filter-box-item">
                <div class="font-medium text-black">Top 10 Holders</div>
                <div class="flex-start-center gap-x-3 mt-3">
                    <span class="text-2xs text-sub-text">Below</span>
                    <div class="input-item-box" [class.active]="!!top10Holders">
                        <nz-input-number
                            [nzFormatter]="formatter"
                            [(ngModel)]="top10Holders"
                            [nzMin]="0"
                            [nzMax]="100"
                            [nzParser]="parser"
                            class="!w-[185px] input-item"
                            nzPlaceHolder="30"
                        ></nz-input-number>
                        <span class="suffix-icon">%</span>
                    </div>

                    <span
                        nz-icon
                        nzType="antd:v2-brush"
                        class="icon-btn"
                        nzTheme="outline"
                        [ngClass]="{
                            'disabled': !top10Holders
                        }"
                        (click)="revertFieldData('top10Holders')"
                    ></span>
                </div>
            </div>

            <div class="filter-box-item">
                <div class="font-medium text-black">Smart Recent 1h Buy($)</div>
                <div class="flex-start-center gap-x-3 mt-3">
                    <div class="input-item-box" [class.active]="!!smartBuyRange[0]">
                        <nz-input-number
                            [nzFormatter]="formatter"
                            [(ngModel)]="smartBuyRange[0]"
                            [nzParser]="parser"
                            class="!w-[108px] input-item"
                            nzPlaceHolder="Min"
                            [nzMin]="0"
                        ></nz-input-number>
                        <span class="suffix-icon">$</span>
                    </div>
                    <div class="input-item-box" [class.active]="!!smartBuyRange[1]">
                        <nz-input-number
                            [nzFormatter]="formatter"
                            [(ngModel)]="smartBuyRange[1]"
                            [nzParser]="parser"
                            class="!w-[108px] input-item"
                            nzPlaceHolder="Max"
                            [nzMin]="smartBuyRange[0]"
                        ></nz-input-number>
                        <span class="suffix-icon">$</span>
                    </div>

                    <span
                        nz-icon
                        nzType="antd:v2-brush"
                        class="icon-btn disabled"
                        nzTheme="outline"
                        [ngClass]="{
                            'disabled': isEmptyFiled(smartBuyRange)
                        }"
                        (click)="revertFieldData('smartBuy')"
                    ></span>
                </div>
            </div>

            <div class="filter-box-item">
                <div class="font-medium text-black">Smart/Watchlist Simple Buy($)</div>
                <div class="flex-start-center gap-x-3 mt-3">
                    <div class="input-item-box" [class.active]="!!smartWatchlistSimpleBuyRange[0]">
                        <nz-input-number
                            [nzFormatter]="formatter"
                            [(ngModel)]="smartWatchlistSimpleBuyRange[0]"
                            [nzParser]="parser"
                            class="!w-[108px] input-item"
                            nzPlaceHolder="Min"
                            [nzMin]="0"
                        ></nz-input-number>
                        <span class="suffix-icon">$</span>
                    </div>
                    <div class="input-item-box" [class.active]="!!smartWatchlistSimpleBuyRange[1]">
                        <nz-input-number
                            [nzFormatter]="formatter"
                            [(ngModel)]="smartWatchlistSimpleBuyRange[1]"
                            [nzParser]="parser"
                            class="!w-[108px] input-item"
                            nzPlaceHolder="Max"
                            [nzMin]="smartWatchlistSimpleBuyRange[0]"
                        ></nz-input-number>
                        <span class="suffix-icon">$</span>
                    </div>

                    <span
                        nz-icon
                        nzType="antd:v2-brush"
                        class="icon-btn disabled"
                        nzTheme="outline"
                        [ngClass]="{
                            'disabled': isEmptyFiled(smartWatchlistSimpleBuyRange)
                        }"
                        (click)="revertFieldData('smartBuy')"
                    ></span>
                </div>
            </div>

            <div class="filter-box-item">
                <div class="flex-start-center gap-x-2">
                    <span class="font-medium text-black">Star Level</span>
                    <span
                        nz-icon
                        nzType="info-circle"
                        class="text-sub-text text-sm"
                        nzTheme="outline"
                        nz-tooltip="The watchlist creator sets filters with specific thresholds, assigning 1 Star 🌟to each filter. Alerts can earn up to 5 Stars based on how many filters they satisfy. You can customize your settings to receive alerts starting at your preferred Star level (e.g., 3 Stars or higher)."
                    ></span>
                </div>

                <div class="flex-start-center mt-3">
                    <div class="flex-start-center gap-x-4 w-[240px]">
                        <img
                            *ngFor="let item of [1,2,3,4,5]; let i = index"
                            [src]="((i < starLevel && !hoverStarLevel) || (hoverStarLevel > 0 && i < hoverStarLevel)) ? '/assets/imgs/icon/meme-star.png' : '/assets/imgs/icon/meme-star-gray.png'"
                            class="w-5 h-5 object-contain"
                            alt=""
                            (mouseenter)="hoverStarLevel = i + 1"
                            (mouseleave)="hoverStarLevel = 0"
                            (click)="starLevel = i + 1"
                        />
                    </div>

                    <span
                        nz-icon
                        nzType="antd:v2-brush"
                        class="icon-btn disabled"
                        nzTheme="outline"
                        [ngClass]="{
                            'disabled': !starLevel
                        }"
                        (click)="starLevel = 0"
                    ></span>
                </div>

                <div *ngIf="starLevel > 0" class="text-2xs text-sub-text mt-2">
                    Receive alerts at {{starLevel}} Stars <ng-container *ngIf="starLevel < 5"> or higher</ng-container>
                </div>
            </div>
        </div>

        <div class="text-center text-xs text-sub-text">
            You can also modify the settings in <span class="cursor-pointer text-[#2990FF]" (click)="goToTgAgent()">TG Hunter Agent.</span>
        </div>
    </div>

    <div class="bg-white sticky bottom-0 pt-4">
        <button nz-button nzSize="large" nzType="primary" class="w-full" (click)="saveSettings()" [nzLoading]="submitting">
            <div class="colorful-text font-semibold ml-2">Save</div>
        </button>
    </div>

    <ng-template #simplifiedTpl>
        <img src="/assets/imgs/simplified.png" class="w-[300px]" alt="" />
    </ng-template>

    <ng-template #comprehensiveTpl>
        <img src="/assets/imgs/comprehensive.png" class="w-[300px]" alt="" />
    </ng-template>
</div>

<div *ngIf="loading" class="h-[100px]" appLoading [loading]="loading"></div>
