<nz-input-group
    [nzPrefix]="prefixTemplateUser"
    class="w-[220px]"
    nz-dropdown
    [nzDropdownMenu]="searchMenu"
    [nzTrigger]="null"
    [(nzVisible)]="searchMenuVisible"
>
    <input
        type="text"
        nz-input
        placeholder="Search Token/Contract"
        class="!leading-1"
        [(ngModel)]="searchedAddress"
        (ngModelChange)="onSearch($event)"
        (focus)="searchFocused()"
    />
</nz-input-group>
<ng-template #prefixTemplateUser><span nz-icon [nzType]="searchingToken ? 'loading' : 'antd:v2-search'" class="!text-lg"></span></ng-template>

<nz-dropdown-menu #searchMenu="nzDropdownMenu">
    <ul nz-menu class="max-h-[400px] overflow-y-auto md:w-[300px]">
        <!-- Visit History -->
        <div *ngIf="visitedTokens?.length" class="flex-start-center w-full gap-3 flex-wrap p-4">
            <div class="w-full flex-between-center text-xs">
                <div class="text-sub-text">Recent</div>
                <div class="text-black cursor-pointer" (click)="clearVisitedTokens()">Clear</div>
            </div>
            <div
                *ngFor="let item of visitedTokens"
                class="flex-start-center gap-2 relative cursor-pointer text-xs"
                [routerLink]="['/meme-dashboard','token',item.chain_name, item.token_address]"
            >
                <nz-avatar
                    [nzText]="item.name|slice:0:2"
                    class="!bg-secondaryCard !text-black !text-[12px] !w-6 !h-6 !leading-6"
                    [nzSrc]="item.logo"
                ></nz-avatar>
                <img
                    *ngIf="item.chain_name"
                    [src]="getChainImg(item.chain_name)"
                    class="w-3 h-3 rounded-full absolute bottom-0 left-4 border border-white"
                    alt=""
                />
                <div class="text-sm flex-1 min-w-0 truncate">{{item.symbol}}</div>
            </div>
        </div>

        <div *ngIf="visitedTokens?.length" class="text-sub-text text-xs mb-3 px-4">Trending</div>

        <div *ngIf="searchingToken" class="flex-center-center p-4">
            <span nz-icon nzType="loading" nzTheme="outline"></span>
        </div>

        <li
            nz-menu-item
            *ngFor="let item of searchedTokens"
            [routerLink]="['/meme-dashboard','token',item.chain_name, item.token_address]"
            (click)="setVisitedTokens(item)"
        >
            <div class="w-full flex-start-center gap-x-4">
                <div class="relative">
                    <nz-avatar [nzSrc]="item.logo" [nzText]="$any(item.name | slice:0:2)" [nzSize]="32"></nz-avatar>

                    <img
                        *ngIf="item.chain_name"
                        [src]="getChainImg(item.chain_name)"
                        class="w-4 h-4 rounded-full absolute bottom-0 -right-2 border border-white"
                        alt=""
                    />
                </div>

                <div class="text-sm flex-1 min-w-0 space-y-1">
                    <div class="w-full flex-between-center gap-x-1">
                        <div class="truncate min-w-0 flex-1">{{item.symbol || '--'}}</div>
                        <div class="min-w-fit max-w-[50%]">MC: ${{item?.price_history[item.price_history.length - 1]?.market_cap | kmb2:2}}</div>
                    </div>

                    <div class="flex-between-center gap-x-1 whitespace-nowrap w-full">
                        <div class="flex-start-center text-sub-text text-xs mt-1 gap-x-1">
                            <div>{{item.token_address | address}}</div>
                        </div>

                        <div
                            class="text-[11px]"
                            [ngClass]="{'text-success': searchedTokenMarketCapChangeMap[item.token_address] > 0, 'text-error': searchedTokenMarketCapChangeMap[item.token_address] < 0}"
                        >
                            <span>
                                {{searchedTokenMarketCapChangeMap[item.token_address] > 0 ? '+' : ''}}{{searchedTokenMarketCapChangeMap[item.token_address] |
                                percent:'1.0-2'}}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </li>

        <ng-container *ngIf="searchedTokens.length === 0 && !searchingToken">
            <div class="text-center p-4 text-sub-text text-sm">No relevant data found.</div>
        </ng-container>
    </ul>
</nz-dropdown-menu>
