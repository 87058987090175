import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MyProfileService } from '@src/app/modules/my-profile/my-profile.service';
import { WalletStatusService } from '@src/app/services/wallet-status.service';
import { SocialscanDirectiveModule } from '@src/app/shared/directives/socialscan-directive.module';
import { PointTaskApiInfo, PointTaskNameMap } from '@src/app/shared/types/point-tasks.type';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';

@Component({
    selector: 'app-points-history',
    standalone: true,
    imports: [CommonModule, NzSkeletonModule, SocialscanDirectiveModule],
    templateUrl: './points-history.component.html',
    styleUrls: ['./points-history.component.less'],
})
export class PointsHistoryComponent {
    @Input() type: string;

    PointTaskNameMap = PointTaskNameMap;

    isLast = false;
    page = 1;
    size = 20;
    pointsHistory: PointTaskApiInfo[];

    constructor(public myProfileService: MyProfileService, public walletStatusService: WalletStatusService) {}

    getPointsHistory() {
        this.myProfileService
            .getPointsHistory({
                page: this.page,
                size: this.size,
                type: this.type,
            })
            .then(data => {
                this.pointsHistory = (this.pointsHistory || []).concat(data.data);
                this.page++;
                this.isLast = this.pointsHistory.length >= data.total;
            });
    }
}
