import { Component, HostBinding, Input } from '@angular/core';

@Component({
    selector: 'app-no-data',
    templateUrl: './no-data.component.html',
    styleUrls: ['./no-data.component.less'],
})
export class NoDataComponent {
    @Input() text: string;
    @HostBinding('style.display') display = 'block';
}
